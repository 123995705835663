#custom-theme-noa--header {
    min-height: 80px;
}
#custom-theme-noa--header,
#custom-theme-noa--footer {
    .tm-header .uk-navbar-left svg {
        height: 70px !important;
    }

    .tm-header .jc-nav--toggled .uk-navbar-left svg {
        height: 50px !important;
    }

    .uk-nav a {
        transition: 0.3s ease-in-out;
    }

    .noa-nav a {
        color: #ffffff;
        transition: 0.3s ease-in-out;
        text-decoration: none;
    }

    .noa-nav a:hover {
        color: var(--shop-main);
        text-decoration: none;
    }

    .uk-navbar-transparent {
        background: rgba(14, 14, 14, 0.4);
    }

    .header-big .rot {
        background: #e80026;
        position: absolute;
        padding: 40px;
        vertical-align: middle;
        font-size: 34px;
        color: #fff;
        width: 200px;
        height: 200px;
        border-radius: 100%;
        hyphens: auto !important;
        text-align: center;
    }

    .header-big .blau {
        background: var(--shop-main);
        position: absolute;
        width: 200px;
        font-size: 34px;
        display: inline-block;
        padding: 40px;
        vertical-align: middle;
        color: #fff;
        hyphens: auto !important;
        text-align: center;
        transition: 0.5s all;
    }

    .header-big .top {
        position: absolute;
        right: 40px;
        top: 20%;
    }

    .header-big .bottom {
        position: absolute;
        right: 40px;
        bottom: 20%;
    }

    .uk-h1 {
        background-image: linear-gradient(var(--shop-main), var(--shop-main));
        background-position: bottom left;
        background-size: 50px 1px;
        background-repeat: no-repeat;
        padding-bottom: 9px;
    }

    .uk-h1.uk-text-center {
        background: none;
    }

    .footer a,
    .footer a i {
        -webkit-transition: 0.1s ease-out;
        -moz-transition: 0.1s ease-out;
        -o-transition: 0.1s ease-out;
        transition: 0.1s ease-out;
    }
    .footer a:hover,
    .footer a:hover i {
        -webkit-transition: 0.6s ease-out;
        -moz-transition: 0.6s ease-out;
        -o-transition: 0.6s ease-out;
        transition: 0.6s ease-out;
    }

    ::marker {
        color: var(--shop-main);
        font-size: 1em;
    }

    /* Custom Code! */

    .uk-navbar-right--toolbar {
        margin-top: 45px;
        margin-right: 0;
        top: 0;
        position: absolute;
        right: 0;
        height: 35px;
        border-top: 1px solid rgb(45, 166, 223);
        display: flex;
        align-items: center;
        transition: margin-right 0.3s 0.3s ease, margin-top 0.3s ease;
    }

    .uk-navbar {
        height: 80px;
        transition: height 0.3s ease;
    }

    .uk-navbar-left svg {
        transition: height 0.3s ease;
        height: 60px !important;
        width: auto !important;
    }

    .uk-navbar-toggle {
        transition: width 0.3s ease;
    }

    .uk-navbar-toggle .uk-margin-small-right {
        position: absolute;
        transition: opacity 0.3s ease;
        right: 35px;
        top: 0;
        bottom: 0;
    }

    .uk-navbar-right {
        align-self: flex-start;
        height: 60px;
    }

    .jc-nav--toggled .uk-navbar-right--toolbar {
        height: 40px;
        margin-top: 10px;
        margin-right: 44px;
        border-top: none;
        transition: margin-right 0.3s ease, margin-top 0.3s 0.3s ease;
    }

    .jc-nav--toggled .uk-navbar {
        height: 60px;
        transition: height 0.3s 0.3s ease;
    }

    .jc-nav--toggled .uk-navbar-left svg {
        height: 50px !important;
        width: auto !important;
    }

    .jc-nav--toggled .uk-navbar-toggle {
        width: 44px !important;
    }

    .jc-nav--toggled .uk-margin-small-right {
        opacity: 0;
    }
    .jc-nav--toggled.uk-navbar-container.uk-navbar-transparent {
        background-color: var(--shop-secondary);
    }
}
