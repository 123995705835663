#custom-theme-noa--header,
#custom-theme-noa--footer {
    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }

    .screen-reader-text:focus {
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000;
    }

    font-family: 'Alegreya Sans', serif;
    font-size: 18px;
    font-weight: inherit;
    line-height: 1.6;
    color: #e2e2e2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    body {
        margin: 0;
    }

    a:active,
    a:hover {
        outline: 0;
    }

    .uk-link,
    a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }

    .uk-link-toggle:focus .uk-link,
    .uk-link-toggle:hover .uk-link,
    .uk-link:hover,
    a:hover {
        color: var(--shop-main);
        text-decoration: none;
    }

    abbr[title] {
        text-decoration: underline dotted;
        -webkit-text-decoration-style: dotted;
    }

    b,
    strong {
        font-weight: bolder;
    }

    :not(pre) > code,
    :not(pre) > kbd,
    :not(pre) > samp {
        font-family: Consolas, monaco, monospace;
        font-size: 11px;
        color: #fff;
        white-space: nowrap;
        padding: 2px 6px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 3px;
    }

    em {
        color: #fff;
    }

    ins {
        background: #ed4d6e;
        color: #fff;
        text-decoration: none;
    }

    mark {
        background: #ed4d6e;
        color: var(--shop-main);
    }

    q {
        font-style: italic;
    }

    small {
        font-size: 80%;
    }

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    audio,
    canvas,
    iframe,
    img,
    svg,
    video {
        vertical-align: middle;
    }

    canvas,
    img,
    video {
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    @supports (display: block) {
        svg {
            max-width: 100%;
            height: auto;
            box-sizing: border-box;
        }
    }

    svg:not(:root) {
        overflow: hidden;
    }

    img:not([src]) {
        min-width: 1px;
        visibility: hidden;
    }

    iframe {
        border: 0;
    }

    address,
    dl,
    fieldset,
    figure,
    ol,
    p,
    pre,
    ul {
        margin: 0 0 20px 0;
    }

    * + address,
    * + dl,
    * + fieldset,
    * + figure,
    * + ol,
    * + p,
    * + pre,
    * + ul {
        margin-top: 20px;
    }

    .uk-h1,
    .uk-h2,
    .uk-h3,
    .uk-h4,
    .uk-h5,
    .uk-h6,
    .uk-heading-2xlarge,
    .uk-heading-large,
    .uk-heading-medium,
    .uk-heading-small,
    .uk-heading-xlarge,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 20px 0;
        font-family: inherit;
        font-weight: 300;
        color: #fff;
        text-transform: inherit;
    }

    * + .uk-h1,
    * + .uk-h2,
    * + .uk-h3,
    * + .uk-h4,
    * + .uk-h5,
    * + .uk-h6,
    * + .uk-heading-2xlarge,
    * + .uk-heading-large,
    * + .uk-heading-medium,
    * + .uk-heading-small,
    * + .uk-heading-xlarge,
    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
        margin-top: 40px;
    }

    .uk-h1,
    h1 {
        font-size: 34px;
        line-height: 1.3;
        color: #e2e2e2;
        font-weight: 300;
        text-transform: none;
    }

    .uk-h2,
    h2 {
        font-size: 25.5px;
        line-height: 1.3;
        color: #e2e2e2;
        text-transform: none;
    }

    .uk-h3,
    h3 {
        font-size: 22px;
        line-height: 1.4;
        color: #e2e2e2;
        text-transform: none;
    }

    .uk-h4,
    h4 {
        font-size: 18px;
        line-height: 1.4;
        color: #e2e2e2;
        text-transform: none;
    }

    .uk-h5,
    h5 {
        font-size: 12px;
        line-height: 1.4;
        color: #e2e2e2;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-h6,
    h6 {
        font-size: 15px;
        line-height: 1.4;
        color: #74adff;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    @media (min-width: 960px) {
        .uk-h1,
        h1 {
            font-size: 40px;
        }

        .uk-h2,
        h2 {
            font-size: 30px;
        }
    }

    ol,
    ul {
        padding-left: 30px;
    }

    ol > li > ol,
    ol > li > ul,
    ul > li > ol,
    ul > li > ul {
        margin: 0;
    }

    dt {
        font-weight: 700;
    }

    dd {
        margin-left: 0;
    }

    .uk-hr,
    hr {
        overflow: visible;
        text-align: inherit;
        margin: 0 0 20px 0;
        border: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    * + .uk-hr,
    * + hr {
        margin-top: 20px;
    }

    address {
        font-style: normal;
    }

    blockquote {
        margin: 0 0 20px 0;
        font-size: 18px;
        line-height: 1.5;
        font-style: italic;
        padding: 0 0 0 40px;
        color: #fff;
        font-weight: 300;
        border-left: 2px solid var(--shop-main);
    }

    * + blockquote {
        margin-top: 20px;
    }

    blockquote p:last-of-type {
        margin-bottom: 0;
    }

    blockquote footer {
        margin-top: 10px;
        font-size: 11px;
        line-height: 1.5;
        color: #cdcdcb;
        font-weight: 500;
        text-transform: uppercase;
    }

    blockquote footer::before {
        content: '— ';
    }

    pre {
        font: 11px/1.5 Consolas, monaco, monospace;
        color: #fff;
        -moz-tab-size: 4;
        tab-size: 4;
        overflow: auto;
        padding: 10px;
        background: var(--shop-secondary);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 3px;
    }

    pre code {
        font-family: Consolas, monaco, monospace;
    }

    ::selection {
        background: #39f;
        color: var(--shop-secondary);
        text-shadow: none;
    }

    details,
    main {
        display: block;
    }

    summary {
        display: list-item;
    }

    template {
        display: none;
    }

    .uk-breakpoint-s::before {
        content: '640px';
    }

    .uk-breakpoint-m::before {
        content: '960px';
    }

    .uk-breakpoint-l::before {
        content: '1200px';
    }

    .uk-breakpoint-xl::before {
        content: '1600px';
    }

    :root {
        --uk-breakpoint-s: 640px;
        --uk-breakpoint-m: 960px;
        --uk-breakpoint-l: 1200px;
        --uk-breakpoint-xl: 1600px;
    }

    .uk-link-muted a,
    a.uk-link-muted {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-link-muted a:hover,
    .uk-link-toggle:focus .uk-link-muted,
    .uk-link-toggle:hover .uk-link-muted,
    a.uk-link-muted:hover {
        color: #fff;
    }

    .uk-link-text a,
    a.uk-link-text {
        color: inherit;
    }

    .uk-link-text a:hover,
    .uk-link-toggle:focus .uk-link-text,
    .uk-link-toggle:hover .uk-link-text,
    a.uk-link-text:hover {
        color: #fff;
    }

    .uk-link-heading a,
    a.uk-link-heading {
        color: inherit;
    }

    .uk-link-heading a:hover,
    .uk-link-toggle:focus .uk-link-heading,
    .uk-link-toggle:hover .uk-link-heading,
    a.uk-link-heading:hover {
        color: var(--shop-main);
        text-decoration: none;
    }

    .uk-link-reset a,
    a.uk-link-reset {
        color: inherit !important;
        text-decoration: none !important;
    }

    .uk-link-toggle {
        color: inherit !important;
        text-decoration: none !important;
    }

    .uk-link-toggle:focus {
        outline: 0;
    }

    .uk-heading-small {
        font-size: 34px;
        line-height: 1.2;
        color: #fff;
    }

    .uk-heading-medium {
        font-size: 44.55px;
        line-height: 1.1;
        color: #fff;
    }

    .uk-heading-large {
        font-size: 54px;
        line-height: 1.1;
        color: #fff;
        font-weight: 200;
    }

    .uk-heading-xlarge {
        font-size: 48px;
        line-height: 1;
        color: #fff;
    }

    .uk-heading-2xlarge {
        font-size: 4rem;
        line-height: 1;
        color: #fff;
    }

    @media (min-width: 960px) {
        .uk-heading-small {
            font-size: 40px;
        }

        .uk-heading-medium {
            font-size: 54px;
        }

        .uk-heading-large {
            font-size: 48px;
        }

        .uk-heading-xlarge {
            font-size: 4rem;
        }

        .uk-heading-2xlarge {
            font-size: 6rem;
        }
    }

    @media (min-width: 1200px) {
        .uk-heading-medium {
            font-size: 48px;
        }

        .uk-heading-large {
            font-size: 4rem;
        }

        .uk-heading-xlarge {
            font-size: 6rem;
        }

        .uk-heading-2xlarge {
            font-size: 8rem;
        }
    }

    .uk-heading-divider {
        padding-bottom: calc(5px + 0.1em);
        border-bottom: calc(0.2px + 0.05em) solid rgba(255, 255, 255, 0.1);
    }

    .uk-heading-bullet {
        position: relative;
    }

    .uk-heading-bullet::before {
        content: '';
        display: inline-block;
        position: relative;
        top: calc(-0.1 * 1em);
        vertical-align: middle;
        height: calc(4px + 0.7em);
        margin-right: calc(5px + 0.2em);
        border-left: calc(5px + 0.1em) solid var(--shop-main);
    }

    .uk-heading-line {
        overflow: hidden;
    }

    .uk-heading-line > * {
        display: inline-block;
        position: relative;
    }

    .uk-heading-line > ::after,
    .uk-heading-line > ::before {
        content: '';
        position: absolute;
        top: calc(50% - (calc(0.7px + 0.05em) / 2));
        width: 2000px;
        border-bottom: calc(0.7px + 0.05em) solid rgba(255, 255, 255, 0.1);
    }

    .uk-heading-line > ::before {
        right: 100%;
        margin-right: calc(5px + 0.3em);
    }

    .uk-heading-line > ::after {
        left: 100%;
        margin-left: calc(5px + 0.3em);
    }

    [class*='uk-divider'] {
        border: none;
        margin-bottom: 20px;
    }

    * + [class*='uk-divider'] {
        margin-top: 20px;
    }

    .uk-divider-icon {
        position: relative;
        height: 0.1px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Ccircle%20fill%3D%22%232DA6DF%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .uk-divider-icon::after,
    .uk-divider-icon::before {
        content: '';
        position: absolute;
        top: 50%;
        max-width: calc(50% - (0px / 2));
        border-bottom: 1px solid #74adff;
    }

    .uk-divider-icon::before {
        right: calc(50% + (0px / 2));
        width: 5%;
    }

    .uk-divider-icon::after {
        left: calc(50% + (0px / 2));
        width: 5%;
    }

    .uk-divider-small {
        line-height: 0;
    }

    .uk-divider-small::after {
        content: '';
        display: inline-block;
        width: 45px;
        max-width: 100%;
        border-top: 1px solid var(--shop-main);
        vertical-align: top;
    }

    .uk-divider-vertical {
        width: 1px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        border-left: 1px solid #fff;
    }

    .tm-child-list > ul,
    .uk-list {
        padding: 0;
        list-style: none;
    }

    .tm-child-list > ul > * > :last-child,
    .uk-list > * > :last-child {
        margin-bottom: 0;
    }

    .tm-child-list > ul > * > ul,
    .tm-child-list > ul > :nth-child(n + 2),
    .uk-list > * > ul,
    .uk-list > :nth-child(n + 2) {
        margin-top: 10px;
    }

    .uk-list-circle > *,
    .uk-list-decimal > *,
    .uk-list-disc > *,
    .uk-list-hyphen > *,
    .uk-list-square > * {
        padding-left: 30px;
    }

    .uk-list-decimal {
        counter-reset: decimal;
    }

    .uk-list-decimal > * {
        counter-increment: decimal;
    }

    [class*='uk-list'] > ::before {
        content: '';
        position: relative;
        left: -30px;
        width: 30px;
        height: 1.6em;
        margin-bottom: -1.6em;
        display: list-item;
        list-style-position: inside;
        text-align: right;
    }

    .uk-list-disc > ::before {
        list-style-type: disc;
    }

    .uk-list-circle > ::before {
        list-style-type: circle;
    }

    .uk-list-square > ::before {
        list-style-type: square;
    }

    .uk-list-decimal > ::before {
        content: counter(decimal, decimal) '\200A.\00A0';
    }

    .uk-list-hyphen > ::before {
        content: '–\00A0\00A0';
    }

    .uk-list-muted > ::before {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-list-emphasis > ::before {
        color: #fff !important;
    }

    .uk-list-primary > ::before {
        color: var(--shop-main) !important;
    }

    .uk-list-secondary > ::before {
        color: rgba(0, 0, 0, 0.74) !important;
    }

    .uk-list-bullet > * {
        padding-left: 30px;
    }

    .uk-list-bullet > ::before {
        content: '';
        position: relative;
        left: -30px;
        width: 30px;
        height: 1.6em;
        margin-bottom: -1.6em;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%204%204%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Ccircle%20fill%3D%22%23CBCBCB%22%20cx%3D%222%22%20cy%3D%222%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .tm-child-list-divider > ul > :nth-child(n + 2),
    .uk-list-divider > :nth-child(n + 2) {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.14);
    }

    .uk-list-striped > * {
        padding: 10px 10px;
    }

    .uk-list-striped > :nth-of-type(odd) {
        background: #2f2f2f;
    }

    .uk-list-striped > :nth-child(n + 2) {
        margin-top: 0;
    }

    .uk-list-large > * > ul,
    .uk-list-large > :nth-child(n + 2) {
        margin-top: 20px;
    }

    .uk-list-collapse > * > ul,
    .uk-list-collapse > :nth-child(n + 2) {
        margin-top: 0;
    }

    .uk-list-large.tm-child-list-divider > ul > :nth-child(n + 2),
    .uk-list-large.uk-list-divider > :nth-child(n + 2) {
        margin-top: 20px;
        padding-top: 20px;
    }

    .uk-list-collapse.tm-child-list-divider > ul > :nth-child(n + 2),
    .uk-list-collapse.uk-list-divider > :nth-child(n + 2) {
        margin-top: 0;
        padding-top: 0;
    }

    .uk-list-large.uk-list-striped > * {
        padding: 20px 10px;
    }

    .uk-list-collapse.uk-list-striped > * {
        padding-top: 0;
        padding-bottom: 0;
    }

    .uk-list-collapse.uk-list-striped > :nth-child(n + 2),
    .uk-list-large.uk-list-striped > :nth-child(n + 2) {
        margin-top: 0;
    }

    .uk-description-list > dt {
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-description-list > dt:nth-child(n + 2) {
        margin-top: 20px;
    }

    .uk-description-list > dd {
        font-size: 16px;
    }

    .uk-description-list-divider > dt:nth-child(n + 2) {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 20px;
    }

    * + .uk-table {
        margin-top: 20px;
    }

    .uk-table th {
        padding: 16px 12px;
        text-align: left;
        vertical-align: bottom;
        font-size: 11px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
    }

    .uk-table td {
        padding: 16px 12px;
        vertical-align: top;
    }

    .uk-table td > :last-child {
        margin-bottom: 0;
    }

    .uk-table tfoot {
        font-size: 11px;
    }

    .uk-table caption {
        font-size: 11px;
        text-align: left;
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-table-middle,
    .uk-table-middle td {
        vertical-align: middle !important;
    }

    .uk-table-divider > :first-child > tr:not(:first-child),
    .uk-table-divider > :not(:first-child) > tr,
    .uk-table-divider > tr:not(:first-child) {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-table-striped tbody tr:nth-of-type(odd),
    .uk-table-striped > tr:nth-of-type(odd) {
        background: #2f2f2f;
    }

    .uk-table-hover tbody tr:hover,
    .uk-table-hover > tr:hover {
        background: rgba(255, 255, 255, 0.04);
    }

    .uk-table tbody tr.uk-active,
    .uk-table > tr.uk-active {
        background: rgba(255, 255, 255, 0.14);
    }

    .uk-table-small td,
    .uk-table-small th {
        padding: 10px 12px;
    }

    .uk-table-large td,
    .uk-table-large th {
        padding: 22px 12px;
    }

    .uk-table-justify td:first-child,
    .uk-table-justify th:first-child {
        padding-left: 0;
    }

    .uk-table-justify td:last-child,
    .uk-table-justify th:last-child {
        padding-right: 0;
    }

    .uk-table-shrink {
        width: 1px;
    }

    .uk-table-expand {
        min-width: 150px;
    }

    .uk-table-link {
        padding: 0 !important;
    }

    .uk-table-link > a {
        display: block;
        padding: 16px 12px;
    }

    .uk-table-small .uk-table-link > a {
        padding: 10px 12px;
    }

    @media (max-width: 959px) {
        .uk-table-responsive,
        .uk-table-responsive tbody,
        .uk-table-responsive td,
        .uk-table-responsive th,
        .uk-table-responsive tr {
            display: block;
        }

        .uk-table-responsive thead {
            display: none;
        }

        .uk-table-responsive td,
        .uk-table-responsive th {
            width: auto !important;
            max-width: none !important;
            min-width: 0 !important;
            overflow: visible !important;
            white-space: normal !important;
        }

        .uk-table-responsive .uk-table-link:not(:first-child) > a,
        .uk-table-responsive td:not(:first-child):not(.uk-table-link),
        .uk-table-responsive th:not(:first-child):not(.uk-table-link) {
            padding-top: 5px !important;
        }

        .uk-table-responsive .uk-table-link:not(:last-child) > a,
        .uk-table-responsive td:not(:last-child):not(.uk-table-link),
        .uk-table-responsive th:not(:last-child):not(.uk-table-link) {
            padding-bottom: 5px !important;
        }

        .uk-table-justify.uk-table-responsive td,
        .uk-table-justify.uk-table-responsive th {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .uk-table tbody tr {
        transition: background-color 0.1s linear;
    }

    .uk-icon {
        margin: 0;
        border: none;
        border-radius: 0;
        overflow: visible;
        font: inherit;
        color: inherit;
        text-transform: none;
        padding: 0;
        background-color: transparent;
        display: inline-block;
        fill: currentcolor;
        line-height: 0;
    }

    button.uk-icon:not(:disabled) {
        cursor: pointer;
    }

    .uk-icon::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    .uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
        fill: currentcolor;
    }

    .uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
        stroke: currentcolor;
    }

    .uk-icon > * {
        transform: translate(0, 0);
    }

    .uk-icon-image {
        width: 20px;
        height: 20px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
    }

    .uk-icon-link {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-icon-link:focus,
    .uk-icon-link:hover {
        color: #cdcdcb;
        outline: 0;
    }

    .uk-active > .uk-icon-link,
    .uk-icon-link:active {
        color: #c1c1be;
    }

    .uk-icon-button {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        border-radius: 500px;
        background: #2f2f2f;
        color: rgba(255, 255, 255, 0.5);
        vertical-align: middle;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-icon-button:focus,
    .uk-icon-button:hover {
        background-color: var(--shop-secondary);
        color: #fff;
        outline: 0;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-active > .uk-icon-button,
    .uk-icon-button:active {
        background-color: var(--shop-secondary);
        color: #cdcdcb;
    }

    .uk-range {
        box-sizing: border-box;
        margin: 0;
        vertical-align: middle;
        max-width: 100%;
        width: 100%;
        -webkit-appearance: none;
        background: 0 0;
        padding: 0;
    }

    .uk-range:focus {
        outline: 0;
    }

    .uk-range::-moz-focus-outer {
        border: none;
    }

    .uk-range::-ms-track {
        height: 13px;
        background: 0 0;
        border-color: transparent;
        color: transparent;
    }

    .uk-range:not(:disabled)::-webkit-slider-thumb {
        cursor: pointer;
    }

    .uk-range:not(:disabled)::-moz-range-thumb {
        cursor: pointer;
    }

    .uk-range:not(:disabled)::-ms-thumb {
        cursor: pointer;
    }

    .uk-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: -6px;
        height: 13px;
        width: 13px;
        border-radius: 500px;
        background: #373737;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    }

    .uk-range::-moz-range-thumb {
        border: none;
        height: 13px;
        width: 13px;
        border-radius: 500px;
        background: #373737;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    }

    .uk-range::-ms-thumb {
        margin-top: 0;
    }

    .uk-range::-ms-thumb {
        border: none;
        height: 13px;
        width: 13px;
        border-radius: 500px;
        background: #373737;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    }

    .uk-range::-ms-tooltip {
        display: none;
    }

    .uk-range::-webkit-slider-runnable-track {
        height: 3px;
        background: rgba(255, 255, 255, 0.12);
    }

    .uk-range:active::-webkit-slider-runnable-track,
    .uk-range:focus::-webkit-slider-runnable-track {
        background: var(--shop-main);
    }

    .uk-range::-moz-range-track {
        height: 3px;
        background: rgba(255, 255, 255, 0.12);
    }

    .uk-range:focus::-moz-range-track {
        background: var(--shop-main);
    }

    .uk-range::-ms-fill-lower,
    .uk-range::-ms-fill-upper {
        height: 3px;
        background: rgba(255, 255, 255, 0.12);
    }

    .uk-range:focus::-ms-fill-lower,
    .uk-range:focus::-ms-fill-upper {
        background: var(--shop-main);
    }

    .uk-checkbox,
    .uk-input,
    .uk-radio,
    .uk-select,
    .uk-textarea {
        box-sizing: border-box;
        margin: 0;
        border-radius: 0;
        font: inherit;
    }

    .uk-input {
        overflow: visible;
    }

    .uk-select {
        text-transform: none;
    }

    .uk-select optgroup {
        font: inherit;
        font-weight: 700;
    }

    .uk-textarea {
        overflow: auto;
    }

    .uk-input[type='search']::-webkit-search-cancel-button,
    .uk-input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .uk-input[type='number']::-webkit-inner-spin-button,
    .uk-input[type='number']::-webkit-outer-spin-button {
        height: auto;
    }

    .uk-input::-moz-placeholder,
    .uk-textarea::-moz-placeholder {
        opacity: 1;
    }

    .uk-checkbox:not(:disabled),
    .uk-radio:not(:disabled) {
        cursor: pointer;
    }

    .uk-fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }

    .uk-input,
    .uk-textarea {
        -webkit-appearance: none;
    }

    .uk-input,
    .uk-select,
    .uk-textarea {
        max-width: 100%;
        width: 100%;
        border: 0 none;
        padding: 0 10px;
        background: var(--shop-secondary);
        color: #cdcdcb;
        transition: 0.2s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-input,
    .uk-select:not([multiple]):not([size]) {
        height: 40px;
        vertical-align: middle;
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-input:not(input),
    .uk-select:not(select) {
        line-height: 38px;
    }

    .uk-select[multiple],
    .uk-select[size],
    .uk-textarea {
        padding-top: 6px;
        padding-bottom: 6px;
        vertical-align: top;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-select[multiple],
    .uk-select[size] {
        resize: vertical;
    }

    .uk-input:focus,
    .uk-select:focus,
    .uk-textarea:focus {
        outline: 0;
        background-color: var(--shop-secondary);
        color: #fff;
        border-color: var(--shop-secondary) !important;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-input:disabled,
    .uk-select:disabled,
    .uk-textarea:disabled {
        background-color: #2f2f2f;
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1) !important;
    }

    .uk-input::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-input::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-textarea::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-textarea::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-form-small {
        font-size: 11px;
    }

    .uk-form-small:not(textarea):not([multiple]):not([size]) {
        height: 30px;
        padding-left: 8px;
        padding-right: 8px;
    }

    [multiple].uk-form-small,
    [size].uk-form-small,
    textarea.uk-form-small {
        padding: 5px 8px;
    }

    .uk-form-small:not(select):not(input):not(textarea) {
        line-height: 28px;
    }

    .uk-form-large {
        font-size: 18px;
    }

    .uk-form-large:not(textarea):not([multiple]):not([size]) {
        height: 55px;
        padding-left: 12px;
        padding-right: 12px;
    }

    [multiple].uk-form-large,
    [size].uk-form-large,
    textarea.uk-form-large {
        padding: 7px 12px;
    }

    .uk-form-large:not(select):not(input):not(textarea) {
        line-height: 53px;
    }

    .uk-form-danger,
    .uk-form-danger:focus {
        color: #ed4d6e;
        border-color: #ed4d6e !important;
    }

    .uk-form-success,
    .uk-form-success:focus {
        color: #51cb20;
        border-color: #51cb20 !important;
    }

    .uk-form-blank {
        background: 0 0;
        border-color: transparent !important;
    }

    .uk-form-blank:focus {
        border-color: rgba(255, 255, 255, 0.1) !important;
        border-style: dashed;
    }

    input.uk-form-width-xsmall {
        width: 50px;
    }

    select.uk-form-width-xsmall {
        width: 75px;
    }

    .uk-form-width-small {
        width: 130px;
    }

    .uk-form-width-medium {
        width: 200px;
    }

    .uk-form-width-large {
        width: 500px;
    }

    .uk-select:not([multiple]):not([size]) {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: 20px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23CDCDCB%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23CDCDCB%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
        background-repeat: no-repeat;
        background-position: 100% 50%;
    }

    .uk-select:not([multiple]):not([size])::-ms-expand {
        display: none;
    }

    .uk-select:not([multiple]):not([size]) option {
        color: #444;
    }

    .uk-select:not([multiple]):not([size]):disabled {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-input[list] {
        padding-right: 20px;
        background-repeat: no-repeat;
        background-position: 100% 50%;
    }

    .uk-input[list]:focus,
    .uk-input[list]:hover {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23CDCDCB%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-input[list]::-webkit-calendar-picker-indicator {
        display: none !important;
    }

    .uk-checkbox,
    .uk-radio {
        display: inline-block;
        height: 16px;
        width: 16px;
        overflow: hidden;
        margin-top: -4px;
        vertical-align: middle;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transition: 0.2s ease-in-out;
        transition-property: background-color, border-color, box-shadow;
        border: 1px solid rgba(242, 242, 242, 0.1);
    }

    .uk-radio {
        border-radius: 50%;
    }

    .uk-checkbox:focus,
    .uk-radio:focus {
        background-color: rgba(0, 0, 0, 0);
        outline: 0;
        border-color: var(--shop-main);
    }

    .uk-checkbox:checked,
    .uk-checkbox:indeterminate,
    .uk-radio:checked {
        background-color: var(--shop-main);
        border-color: transparent;
    }

    .uk-checkbox:checked:focus,
    .uk-checkbox:indeterminate:focus,
    .uk-radio:checked:focus {
        background-color: #1d89bc;
    }

    .uk-radio:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%230E0E0E%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-checkbox:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%230E0E0E%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-checkbox:indeterminate {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%230E0E0E%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-checkbox:disabled,
    .uk-radio:disabled {
        background-color: #2f2f2f;
        border-color: rgba(255, 255, 255, 0.1);
    }

    .uk-radio:disabled:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-checkbox:disabled:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-checkbox:disabled:indeterminate {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-legend {
        width: 100%;
        color: inherit;
        padding: 0;
        font-size: 22px;
        line-height: 1.4;
    }

    .uk-form-custom {
        display: inline-block;
        position: relative;
        max-width: 100%;
        vertical-align: middle;
    }

    .uk-form-custom input[type='file'],
    .uk-form-custom select {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        -webkit-appearance: none;
        opacity: 0;
        cursor: pointer;
    }

    .uk-form-custom input[type='file'] {
        font-size: 500px;
        overflow: hidden;
    }

    .uk-form-label {
        font-size: 11px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-form-stacked .uk-form-label {
        display: block;
        margin-bottom: 10px;
    }

    @media (max-width: 959px) {
        .uk-form-horizontal .uk-form-label {
            display: block;
            margin-bottom: 10px;
        }
    }

    @media (min-width: 960px) {
        .uk-form-horizontal .uk-form-label {
            width: 200px;
            margin-top: 7px;
            float: left;
        }

        .uk-form-horizontal .uk-form-controls {
            margin-left: 215px;
        }

        .uk-form-horizontal .uk-form-controls-text {
            padding-top: 7px;
        }
    }

    .uk-form-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-form-icon:hover {
        color: #cdcdcb;
    }

    .uk-form-icon:not(a):not(button):not(input) {
        pointer-events: none;
    }

    .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
        padding-left: 40px !important;
    }

    .uk-form-icon-flip {
        right: 0;
        left: auto;
    }

    .uk-form-icon-flip ~ .uk-input {
        padding-right: 40px !important;
    }

    .uk-button {
        margin: 0;
        border: none;
        overflow: visible;
        font: inherit;
        color: inherit;
        -webkit-appearance: none;
        display: inline-block;
        box-sizing: border-box;
        padding: 0 30px;
        vertical-align: middle;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        transition-property: color, background-color, background-position, border-color, box-shadow;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 1px;
        border-radius: 500px;
        background-origin: border-box;
    }

    .uk-button:not(:disabled) {
        cursor: pointer;
    }

    .uk-button::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    .uk-button:hover {
        text-decoration: none;
    }

    .uk-button:focus {
        outline: 0;
    }

    .uk-button-default {
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        border: 1.5px solid var(--shop-main);
        box-shadow: 0 40px 80px -20px rgba(224, 207, 207, 0.4);
    }

    .uk-button-default:focus,
    .uk-button-default:hover {
        background-color: #202020;
        color: #f9f9f9;
        border-color: #fff;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-button-default.uk-active,
    .uk-button-default:active {
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        border-color: transparent;
    }

    .uk-button-primary {
        background-color: var(--shop-main);
        color: #fff;
        border: 1.5px solid transparent;
    }

    .uk-button-primary:focus,
    .uk-button-primary:hover {
        background-color: #4cb3e4;
        color: #fff;
        border-color: #fff;
    }

    .uk-button-primary.uk-active,
    .uk-button-primary:active {
        background-color: #1d89bc;
        color: #fff;
        border-color: transparent;
    }

    .uk-button-secondary {
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        border: 1.5px solid var(--shop-main);
    }

    .uk-button-secondary:focus,
    .uk-button-secondary:hover {
        background-color: #000;
        color: #fff;
        border-color: #fff;
    }

    .uk-button-secondary.uk-active,
    .uk-button-secondary:active {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
        border-color: transparent;
    }

    .uk-button-danger {
        background-color: transparent;
        color: #ed4d6e;
        border: 1.5px solid #ed4d6e;
    }

    .uk-button-danger:focus,
    .uk-button-danger:hover {
        background-color: transparent;
        color: rgba(237, 77, 110, 0.7);
        border-color: #ed4d6e;
    }

    .uk-button-danger.uk-active,
    .uk-button-danger:active {
        background-color: #ed4d6e;
        color: #fff;
        border-color: #ed4d6e;
    }

    .uk-button-danger:disabled,
    .uk-button-default:disabled,
    .uk-button-primary:disabled,
    .uk-button-secondary:disabled {
        background-color: #2f2f2f;
        color: rgba(255, 255, 255, 0.5);
        border: 1.5px solid transparent;
        background-image: none;
        box-shadow: none;
    }

    .uk-button-small {
        padding: 0 20px;
        line-height: 27px;
        font-size: 11px;
        border-radius: 500px;
    }

    .uk-button-large {
        padding: 0 40px;
        line-height: 52px;
        font-size: 20px;
        border-radius: 500px;
    }

    .uk-button-text {
        padding: 0;
        line-height: 1.6;
        background: 0 0;
        color: #fff;
        border-radius: 0;
    }

    .uk-button-text:focus,
    .uk-button-text:hover {
        color: #fff;
    }

    .uk-button-text:disabled {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-button-link {
        padding: 0;
        line-height: 1.6;
        background: 0 0;
        color: #fff;
    }

    .uk-button-link:focus,
    .uk-button-link:hover {
        color: rgba(234, 246, 255, 0.67);
        text-decoration: none;
    }

    .uk-button-link:disabled {
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
    }

    .uk-button-group {
        display: inline-flex;
        vertical-align: middle;
        position: relative;
    }

    .uk-button-group > .uk-button:nth-child(n + 2),
    .uk-button-group > div:nth-child(n + 2) .uk-button {
        margin-left: -1.5px;
    }

    .uk-button-group .uk-button.uk-active,
    .uk-button-group .uk-button:active,
    .uk-button-group .uk-button:focus,
    .uk-button-group .uk-button:hover {
        position: relative;
        z-index: 1;
    }

    .uk-button-group > .uk-button:not(:first-child):not(:last-child),
    .uk-button-group > div:not(:first-child):not(:last-child) .uk-button {
        border-radius: 0;
    }

    .uk-button-group > .uk-button:first-child,
    .uk-button-group > div:first-child .uk-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .uk-button-group > .uk-button:last-child,
    .uk-button-group > div:last-child .uk-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .uk-progress {
        vertical-align: baseline;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: block;
        width: 100%;
        border: 0;
        background-color: #2f2f2f;
        margin-bottom: 20px;
        height: 15px;
        border-radius: 500px;
        overflow: hidden;
    }

    * + .uk-progress {
        margin-top: 20px;
    }

    .uk-progress:indeterminate {
        color: transparent;
    }

    .uk-progress::-webkit-progress-bar {
        background-color: #2f2f2f;
        border-radius: 500px;
        overflow: hidden;
    }

    .uk-progress:indeterminate::-moz-progress-bar {
        width: 0;
    }

    .uk-progress::-webkit-progress-value {
        background-color: var(--shop-main);
        transition: width 0.6s ease;
    }

    .uk-progress::-moz-progress-bar {
        background-color: var(--shop-main);
    }

    .uk-progress::-ms-fill {
        background-color: var(--shop-main);
        transition: width 0.6s ease;
        border: 0;
    }

    .uk-section {
        display: flow-root;
        box-sizing: border-box;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @media (min-width: 960px) {
        .uk-section {
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }

    .uk-section > :last-child {
        margin-bottom: 0;
    }

    .uk-section-xsmall {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .uk-section-small {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .uk-section-large {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @media (min-width: 960px) {
        .uk-section-large {
            padding-top: 140px;
            padding-bottom: 140px;
        }
    }

    .uk-section-xlarge {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    @media (min-width: 960px) {
        .uk-section-xlarge {
            padding-top: 210px;
            padding-bottom: 210px;
        }
    }

    .uk-section-default {
        background: var(--shop-secondary);
    }

    .uk-section-muted {
        background: #0e273a;
    }

    .uk-section-primary {
        background: var(--shop-main);
    }

    .uk-section-secondary {
        background: rgba(0, 0, 0, 0.74);
    }

    .uk-container {
        display: flow-root;
        box-sizing: content-box;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 640px) {
        .uk-container {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width: 960px) {
        .uk-container {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .uk-container > :last-child {
        margin-bottom: 0;
    }

    .uk-container .uk-container {
        padding-left: 0;
        padding-right: 0;
    }

    .uk-container-xsmall {
        max-width: 750px;
    }

    .uk-container-small {
        max-width: 900px;
    }

    .uk-container-large {
        max-width: 1280px;
    }

    .uk-container-xlarge {
        max-width: 1600px;
    }

    .uk-container-expand {
        max-width: none;
    }

    .uk-container-expand-left {
        margin-left: 0;
    }

    .uk-container-expand-right {
        margin-right: 0;
    }

    @media (min-width: 640px) {
        .uk-container-expand-left.uk-container-xsmall,
        .uk-container-expand-right.uk-container-xsmall {
            max-width: calc(50% + (750px / 2) - 20px);
        }

        .uk-container-expand-left.uk-container-small,
        .uk-container-expand-right.uk-container-small {
            max-width: calc(50% + (900px / 2) - 20px);
        }
    }

    @media (min-width: 960px) {
        .uk-container-expand-left,
        .uk-container-expand-right {
            max-width: calc(50% + (1200px / 2) - 40px);
        }

        .uk-container-expand-left.uk-container-xsmall,
        .uk-container-expand-right.uk-container-xsmall {
            max-width: calc(50% + (750px / 2) - 40px);
        }

        .uk-container-expand-left.uk-container-small,
        .uk-container-expand-right.uk-container-small {
            max-width: calc(50% + (900px / 2) - 40px);
        }

        .uk-container-expand-left.uk-container-large,
        .uk-container-expand-right.uk-container-large {
            max-width: calc(50% + (1280px / 2) - 40px);
        }

        .uk-container-expand-left.uk-container-xlarge,
        .uk-container-expand-right.uk-container-xlarge {
            max-width: calc(50% + (1600px / 2) - 40px);
        }
    }

    .uk-container-item-padding-remove-left,
    .uk-container-item-padding-remove-right {
        width: calc(100% + 15px);
    }

    .uk-container-item-padding-remove-left {
        margin-left: -15px;
    }

    .uk-container-item-padding-remove-right {
        margin-right: -15px;
    }

    @media (min-width: 640px) {
        .uk-container-item-padding-remove-left,
        .uk-container-item-padding-remove-right {
            width: calc(100% + 20px);
        }

        .uk-container-item-padding-remove-left {
            margin-left: -20px;
        }

        .uk-container-item-padding-remove-right {
            margin-right: -20px;
        }
    }

    @media (min-width: 960px) {
        .uk-container-item-padding-remove-left,
        .uk-container-item-padding-remove-right {
            width: calc(100% + 40px);
        }

        .uk-container-item-padding-remove-left {
            margin-left: -40px;
        }

        .uk-container-item-padding-remove-right {
            margin-right: -40px;
        }
    }

    .uk-tile {
        display: flow-root;
        position: relative;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @media (min-width: 640px) {
        .uk-tile {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width: 960px) {
        .uk-tile {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }

    .uk-tile > :last-child {
        margin-bottom: 0;
    }

    .uk-tile-xsmall {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .uk-tile-small {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .uk-tile-large {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @media (min-width: 960px) {
        .uk-tile-large {
            padding-top: 140px;
            padding-bottom: 140px;
        }
    }

    .uk-tile-xlarge {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    @media (min-width: 960px) {
        .uk-tile-xlarge {
            padding-top: 210px;
            padding-bottom: 210px;
        }
    }

    .uk-tile-default {
        background: var(--shop-secondary);
    }

    .uk-tile-muted {
        background: #2f2f2f;
    }

    .uk-tile-primary {
        background: var(--shop-main);
    }

    .uk-tile-secondary {
        background: rgba(0, 0, 0, 0.74);
    }

    .uk-card {
        position: relative;
        box-sizing: border-box;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow, transform, top;
    }

    .uk-card-body {
        display: flow-root;
        padding: 20px 20px;
    }

    .uk-card-header {
        display: flow-root;
        padding: 10px 20px;
    }

    .uk-card-footer {
        display: flow-root;
        padding: 10px 20px;
    }

    @media (min-width: 1200px) {
        .uk-card-body {
            padding: 40px 40px;
        }

        .uk-card-header {
            padding: 20px 40px;
        }

        .uk-card-footer {
            padding: 20px 40px;
        }
    }

    .uk-card-body > :last-child,
    .uk-card-footer > :last-child,
    .uk-card-header > :last-child {
        margin-bottom: 0;
    }

    .uk-card-title {
        font-size: 22px;
        line-height: 1.4;
        font-weight: 300;
    }

    .uk-card-badge {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
        height: 25px;
        padding: 0 11px;
        background: var(--shop-main);
        color: #cdcdcb;
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 500px;
    }

    .uk-card-badge:first-child + * {
        margin-top: 0;
    }

    .uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
        background: #041d2f;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    .uk-card-default {
        background: #14344d;
        color: #cdcdcb;
    }

    .uk-card-default .uk-card-title {
        color: #fff;
    }

    .uk-card-default.uk-card-hover:hover {
        background-color: #14344d;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-card-default .uk-card-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-card-default .uk-card-footer {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-card-primary {
        background: var(--shop-main);
        color: rgba(255, 255, 255, 0.7);
    }

    .uk-card-primary .uk-card-title {
        color: #fff;
    }

    .uk-card-primary.uk-card-hover:hover {
        background-color: #0f948e;
    }

    .uk-card-secondary {
        background: #05233a;
        color: #cdcdcb;
        border-top: 2px solid var(--shop-main);
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    .uk-card-secondary .uk-card-title {
        color: #fff;
    }

    .uk-card-secondary.uk-card-hover:hover {
        background-color: #05233a;
        border-top-color: var(--shop-main);
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-card-small .uk-card-body,
    .uk-card-small.uk-card-body {
        padding: 20px 20px;
    }

    .uk-card-small .uk-card-header {
        padding: 13px 20px;
    }

    .uk-card-small .uk-card-footer {
        padding: 13px 20px;
    }

    @media (min-width: 1200px) {
        .uk-card-large .uk-card-body,
        .uk-card-large.uk-card-body {
            padding: 70px 70px;
        }

        .uk-card-large .uk-card-header {
            padding: 35px 70px;
        }

        .uk-card-large .uk-card-footer {
            padding: 35px 70px;
        }
    }

    .uk-card-body > .uk-nav-default {
        margin-left: -20px;
        margin-right: -20px;
    }

    .uk-card-body > .uk-nav-default:only-child {
        margin-top: -5px;
        margin-bottom: -5px;
    }

    .uk-card-body .uk-nav-default .uk-nav-divider,
    .uk-card-body .uk-nav-default .uk-nav-header,
    .uk-card-body .uk-nav-default > li > a {
        padding-left: 20px;
        padding-right: 20px;
    }

    .uk-card-body .uk-nav-default .uk-nav-sub {
        padding-left: 35px;
    }

    @media (min-width: 1200px) {
        .uk-card-body > .uk-nav-default {
            margin-left: -40px;
            margin-right: -40px;
        }

        .uk-card-body > .uk-nav-default:only-child {
            margin-top: -25px;
            margin-bottom: -25px;
        }

        .uk-card-body .uk-nav-default .uk-nav-divider,
        .uk-card-body .uk-nav-default .uk-nav-header,
        .uk-card-body .uk-nav-default > li > a {
            padding-left: 40px;
            padding-right: 40px;
        }

        .uk-card-body .uk-nav-default .uk-nav-sub {
            padding-left: 55px;
        }
    }

    .uk-card-small > .uk-nav-default {
        margin-left: -20px;
        margin-right: -20px;
    }

    .uk-card-small > .uk-nav-default:only-child {
        margin-top: -5px;
        margin-bottom: -5px;
    }

    .uk-card-small .uk-nav-default .uk-nav-divider,
    .uk-card-small .uk-nav-default .uk-nav-header,
    .uk-card-small .uk-nav-default > li > a {
        padding-left: 20px;
        padding-right: 20px;
    }

    .uk-card-small .uk-nav-default .uk-nav-sub {
        padding-left: 35px;
    }

    @media (min-width: 1200px) {
        .uk-card-large > .uk-nav-default {
            margin: 0;
        }

        .uk-card-large > .uk-nav-default:only-child {
            margin: 0;
        }

        .uk-card-large .uk-nav-default .uk-nav-divider,
        .uk-card-large .uk-nav-default .uk-nav-header,
        .uk-card-large .uk-nav-default > li > a {
            padding-left: 0;
            padding-right: 0;
        }

        .uk-card-large .uk-nav-default .uk-nav-sub {
            padding-left: 15px;
        }
    }

    .uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary) {
        box-shadow: 0 0 0 transparent, 0 0 0 transparent;
    }

    .uk-close {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, opacity;
    }

    .uk-close:focus,
    .uk-close:hover {
        color: #fff;
        outline: 0;
    }

    .uk-spinner > * {
        animation: uk-spinner-rotate 1.4s linear infinite;
    }

    @keyframes uk-spinner-rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(270deg);
        }
    }

    .uk-spinner > * > * {
        stroke-dasharray: 88px;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: uk-spinner-dash 1.4s ease-in-out infinite;
        stroke-width: 1;
        stroke-linecap: round;
    }

    @keyframes uk-spinner-dash {
        0% {
            stroke-dashoffset: 88px;
        }
        50% {
            stroke-dashoffset: 22px;
            transform: rotate(135deg);
        }
        100% {
            stroke-dashoffset: 88px;
            transform: rotate(450deg);
        }
    }

    .uk-totop {
        padding: 5px;
        color: rgba(205, 205, 203, 0.5);
        background: 0 0;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-totop:focus,
    .uk-totop:hover {
        color: #fff;
        outline: 0;
        background-color: transparent;
    }

    .uk-totop:active {
        color: var(--shop-main);
        background-color: transparent;
    }

    .uk-marker {
        padding: 5px;
        background: rgba(0, 0, 0, 0.74);
        color: var(--shop-secondary);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color;
        border-radius: 500px;
    }

    .uk-marker:focus,
    .uk-marker:hover {
        color: var(--shop-secondary);
        outline: 0;
    }

    .uk-alert {
        position: relative;
        margin-bottom: 20px;
        padding: 15px 29px 15px 15px;
        background: #032237;
        color: #fff;
    }

    * + .uk-alert {
        margin-top: 20px;
    }

    .uk-alert > :last-child {
        margin-bottom: 0;
    }

    .uk-alert-close {
        position: absolute;
        top: 20px;
        right: 15px;
        color: inherit;
        opacity: 0.4;
    }

    .uk-alert-close:first-child + * {
        margin-top: 0;
    }

    .uk-alert-close:focus,
    .uk-alert-close:hover {
        color: inherit;
        opacity: 0.8;
    }

    .uk-alert-primary {
        background: var(--shop-main);
        color: #fff;
    }

    .uk-alert-success {
        background: #33c15e;
        color: #fff;
    }

    .uk-alert-warning {
        background: #ffad45;
        color: #fff;
    }

    .uk-alert-danger {
        background: #ba3223;
        color: #fff;
    }

    .uk-alert h1,
    .uk-alert h2,
    .uk-alert h3,
    .uk-alert h4,
    .uk-alert h5,
    .uk-alert h6 {
        color: inherit;
    }

    .uk-alert a:not([class]) {
        color: inherit;
        text-decoration: underline;
    }

    .uk-alert a:not([class]):hover {
        color: inherit;
        text-decoration: underline;
    }

    .uk-placeholder {
        margin-bottom: 20px;
        padding: 20px 20px;
        background: 0 0;
        border: 1px dashed rgba(255, 255, 255, 0.1);
    }

    * + .uk-placeholder {
        margin-top: 20px;
    }

    .uk-placeholder > :last-child {
        margin-bottom: 0;
    }

    .uk-badge {
        box-sizing: border-box;
        min-width: 18px;
        height: 18px;
        padding: 0 5px;
        border-radius: 500px;
        vertical-align: middle;
        background: var(--shop-main);
        color: #fff !important;
        font-size: 11px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        line-height: 0;
        letter-spacing: 0;
    }

    .uk-badge:focus,
    .uk-badge:hover {
        text-decoration: none;
        outline: 0;
    }

    .uk-label {
        display: inline-block;
        padding: 1px 8px;
        background: var(--shop-main);
        line-height: 1.6;
        font-size: 11px;
        color: #fff;
        vertical-align: middle;
        white-space: nowrap;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border-radius: 2px;
    }

    .uk-label-success {
        background-color: #51cb20;
        color: #fff;
    }

    .uk-label-warning {
        background-color: #deb986;
        color: #fff;
    }

    .uk-label-danger {
        background-color: #ed4d6e;
        color: #fff;
    }

    .uk-overlay {
        padding: 20px 20px;
    }

    .uk-overlay > :last-child {
        margin-bottom: 0;
    }

    .uk-overlay-default {
        background: rgba(14, 14, 14, 0.8);
    }

    .uk-overlay-primary {
        background: rgba(0, 0, 0, 0.8);
    }

    .uk-article {
        display: flow-root;
    }

    .uk-article > :last-child {
        margin-bottom: 0;
    }

    .uk-article + .uk-article {
        margin-top: 70px;
    }

    .uk-article-title {
        font-size: 34px;
        line-height: 1.2;
        color: #fff;
        font-weight: 300;
    }

    @media (min-width: 960px) {
        .uk-article-title {
            font-size: 40px;
        }
    }

    .uk-article-meta {
        font-size: 11px;
        line-height: 1.4;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-article-meta a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-article-meta a:hover {
        color: #cdcdcb;
        text-decoration: none;
    }

    .uk-comment-body {
        display: flow-root;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    .uk-comment-header {
        display: flow-root;
        margin-bottom: 20px;
    }

    .uk-comment-body > :last-child,
    .uk-comment-header > :last-child {
        margin-bottom: 0;
    }

    .uk-comment-title {
        font-size: 18px;
        line-height: 1.4;
    }

    .uk-comment-meta {
        font-size: 11px;
        line-height: 1.4;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-comment-list {
        padding: 0;
        list-style: none;
    }

    .uk-comment-list > :nth-child(n + 2) {
        margin-top: 70px;
    }

    .uk-comment-list .uk-comment ~ ul {
        margin: 70px 0 0 0;
        padding-left: 30px;
        list-style: none;
    }

    @media (min-width: 960px) {
        .uk-comment-list .uk-comment ~ ul {
            padding-left: 100px;
        }
    }

    .uk-comment-list .uk-comment ~ ul > :nth-child(n + 2) {
        margin-top: 70px;
    }

    .uk-comment-primary {
        padding: 20px;
        background-color: #2f2f2f;
    }

    .uk-search {
        display: inline-block;
        position: relative;
        max-width: 100%;
        margin: 0;
    }

    .uk-search-input::-webkit-search-cancel-button,
    .uk-search-input::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .uk-search-input::-moz-placeholder {
        opacity: 1;
    }

    .uk-search-input {
        box-sizing: border-box;
        margin: 0;
        border-radius: 0;
        font: inherit;
        overflow: visible;
        -webkit-appearance: none;
        vertical-align: middle;
        width: 100%;
        border: none;
        color: #fff;
        transition: 0.2s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-search-input:focus {
        outline: 0;
    }

    .uk-search-input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-search-input::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-search-icon:focus {
        outline: 0;
    }

    .uk-search .uk-search-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-search .uk-search-icon:hover {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-search .uk-search-icon:not(a):not(button):not(input) {
        pointer-events: none;
    }

    .uk-search .uk-search-icon-flip {
        right: 0;
        left: auto;
    }

    .uk-search-default {
        width: 240px;
    }

    .uk-search-default .uk-search-input {
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        background: 0 0;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-search-default .uk-search-input:focus {
        background-color: rgba(0, 0, 0, 0);
        border-color: transparent;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-search-default .uk-search-icon {
        width: 40px;
    }

    .uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
        padding-left: 40px;
    }

    .uk-search-default .uk-search-icon-flip ~ .uk-search-input {
        padding-right: 40px;
    }

    .uk-search-navbar {
        width: 400px;
    }

    .uk-search-navbar .uk-search-input {
        height: 40px;
        background: 0 0;
        font-size: 18px;
    }

    .uk-search-navbar .uk-search-icon {
        width: 40px;
    }

    .uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
        padding-left: 40px;
    }

    .uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
        padding-right: 40px;
    }

    .uk-search-large {
        width: 500px;
    }

    .uk-search-large .uk-search-input {
        height: 80px;
        background: 0 0;
        font-size: 30px;
    }

    .uk-search-large .uk-search-icon {
        width: 80px;
    }

    .uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
        padding-left: 80px;
    }

    .uk-search-large .uk-search-icon-flip ~ .uk-search-input {
        padding-right: 80px;
    }

    .uk-search-toggle {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-search-toggle:focus,
    .uk-search-toggle:hover {
        color: #fff;
    }

    .uk-accordion {
        padding: 0;
        list-style: none;
    }

    .uk-accordion > :nth-child(n + 2) {
        margin-top: 20px;
    }

    .uk-accordion-title {
        display: block;
        font-size: 18px;
        line-height: 1.4;
        color: #fff;
        padding: 0 0;
        background: 0 0;
        overflow: hidden;
        font-weight: 300;
    }

    .uk-accordion-title::before {
        content: '';
        width: 1.4em;
        height: 1.4em;
        margin-left: 10px;
        float: right;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23CDCDCB%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23CDCDCB%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .uk-open > .uk-accordion-title::before {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23CDCDCB%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-accordion-title:focus,
    .uk-accordion-title:hover {
        color: #cdcdcb;
        text-decoration: none;
        outline: 0;
    }

    .uk-accordion-content {
        display: flow-root;
        margin-top: 20px;
    }

    .uk-accordion-content > :last-child {
        margin-bottom: 0;
    }

    .uk-drop {
        display: none;
        position: absolute;
        z-index: 1020;
        box-sizing: border-box;
        width: 300px;
    }

    .uk-drop.uk-open {
        display: block;
    }

    [class*='uk-drop-top'] {
        margin-top: -20px;
    }

    [class*='uk-drop-bottom'] {
        margin-top: 20px;
    }

    [class*='uk-drop-left'] {
        margin-left: -20px;
    }

    [class*='uk-drop-right'] {
        margin-left: 20px;
    }

    .uk-drop-stack .uk-drop-grid > * {
        width: 100% !important;
    }

    .uk-dropdown {
        display: none;
        position: absolute;
        z-index: 1020;
        box-sizing: border-box;
        min-width: 200px;
        padding: 25px;
        background: var(--shop-secondary);
        color: #cdcdcb;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-dropdown.uk-open {
        display: block;
    }

    .uk-dropdown-nav {
        white-space: nowrap;
        margin-left: 0;
        margin-right: 0;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-dropdown-nav > li > a {
        color: rgba(255, 255, 255, 0.5);
        padding: 5px 0;
    }

    .uk-dropdown-nav > li.uk-active > a,
    .uk-dropdown-nav > li > a:focus,
    .uk-dropdown-nav > li > a:hover {
        color: #fff;
        background-color: transparent;
    }

    .uk-dropdown-nav .uk-nav-header {
        color: var(--shop-main);
        padding: 5px 0;
    }

    .uk-dropdown-nav .uk-nav-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin-left: 0;
        margin-right: 0;
    }

    .uk-dropdown-nav .uk-nav-sub a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-dropdown-nav .uk-nav-sub a:focus,
    .uk-dropdown-nav .uk-nav-sub a:hover,
    .uk-dropdown-nav .uk-nav-sub li.uk-active > a {
        color: var(--shop-main);
    }

    [class*='uk-dropdown-top'] {
        margin-top: -10px;
    }

    [class*='uk-dropdown-bottom'] {
        margin-top: 10px;
    }

    [class*='uk-dropdown-left'] {
        margin-left: -10px;
    }

    [class*='uk-dropdown-right'] {
        margin-left: 10px;
    }

    .uk-dropdown-stack .uk-dropdown-grid > * {
        width: 100% !important;
    }

    .uk-dropdown-nav .uk-nav-sub {
        padding-left: 15px;
    }

    .uk-modal {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1010;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 15px 15px;
        background: rgba(0, 0, 0, 0.14);
        opacity: 0;
        transition: opacity 0.15s linear;
    }

    @media (min-width: 640px) {
        .uk-modal {
            padding: 50px 20px;
        }
    }

    @media (min-width: 960px) {
        .uk-modal {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .uk-modal.uk-open {
        opacity: 1;
    }

    .uk-modal-page {
        overflow: hidden;
    }

    .uk-modal-dialog {
        position: relative;
        box-sizing: border-box;
        margin: 0 auto;
        width: 600px;
        max-width: calc(100% - 0.01px) !important;
        background: var(--shop-secondary);
        opacity: 0;
        transform: translateY(-100px);
        transition: 0.3s linear;
        transition-property: opacity, transform;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    .uk-open > .uk-modal-dialog {
        opacity: 1;
        transform: translateY(0);
    }

    .uk-modal-container .uk-modal-dialog {
        width: 1200px;
    }

    .uk-modal-full {
        padding: 0;
        background: 0 0;
    }

    .uk-modal-full .uk-modal-dialog {
        margin: 0;
        width: 100%;
        max-width: 100%;
        transform: translateY(0);
    }

    .uk-modal-body {
        display: flow-root;
        padding: 20px 20px;
    }

    .uk-modal-header {
        display: flow-root;
        padding: 10px 20px;
        background: var(--shop-secondary);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-modal-footer {
        display: flow-root;
        padding: 10px 20px;
        background: var(--shop-secondary);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-modal-body > :last-child,
    .uk-modal-footer > :last-child,
    .uk-modal-header > :last-child {
        margin-bottom: 0;
    }

    .uk-modal-title {
        font-size: 30px;
        line-height: 1.3;
        font-weight: 300;
    }

    [class*='uk-modal-close-'] {
        position: absolute;
        z-index: 1010;
        top: 10px;
        right: 10px;
        padding: 5px;
    }

    [class*='uk-modal-close-']:first-child + * {
        margin-top: 0;
    }

    .uk-modal-close-outside {
        top: 0;
        right: -5px;
        transform: translate(0, -100%);
        color: #414141;
    }

    .uk-modal-close-outside:hover {
        color: var(--shop-secondary);
    }

    @media (min-width: 960px) {
        .uk-modal-close-outside {
            right: 0;
            transform: translate(100%, -100%);
        }
    }

    .uk-modal-close-full {
        top: 0;
        right: 0;
        padding: 20px;
        background: var(--shop-secondary);
    }

    .uk-slideshow {
        -webkit-tap-highlight-color: transparent;
    }

    .uk-slideshow-items {
        position: relative;
        z-index: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        -webkit-touch-callout: none;
    }

    .uk-slideshow-items > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        will-change: transform, opacity;
        touch-action: pan-y;
    }

    .uk-slideshow-items > :focus {
        outline: 0;
    }

    .uk-slideshow-items > :not(.uk-active) {
        display: none;
    }

    .uk-slider {
        -webkit-tap-highlight-color: transparent;
    }

    .uk-slider-container {
        overflow: hidden;
    }

    .uk-slider-container-offset {
        margin: -30px -35px -56px -35px;
        padding: 30px 35px 56px 35px;
    }

    .uk-slider-items {
        will-change: transform;
        position: relative;
    }

    .uk-slider-items:not(.uk-grid) {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        -webkit-touch-callout: none;
    }

    .uk-slider-items.uk-grid {
        flex-wrap: nowrap;
    }

    .uk-slider-items > * {
        flex: none;
        max-width: 100%;
        position: relative;
        touch-action: pan-y;
    }

    .uk-slider-items > :focus {
        outline: 0;
    }

    .uk-sticky-fixed {
        z-index: 980;
        box-sizing: border-box;
        margin: 0 !important;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .uk-sticky[class*='uk-animation-'] {
        animation-duration: 0.2s;
    }

    .uk-sticky.uk-animation-reverse {
        animation-duration: 0.2s;
    }

    .uk-offcanvas {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
    }

    .uk-offcanvas-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -270px;
        box-sizing: border-box;
        width: 270px;
        padding: 20px 20px;
        background: var(--shop-secondary);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    @media (min-width: 960px) {
        .uk-offcanvas-bar {
            left: -350px;
            width: 350px;
            padding: 40px 40px;
        }
    }

    .uk-open > .uk-offcanvas-bar {
        left: 0;
    }

    .uk-offcanvas-bar-animation {
        transition: left 0.3s ease-out;
    }

    .uk-offcanvas-reveal {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        overflow: hidden;
        transition: width 0.3s ease-out;
    }

    .uk-offcanvas-reveal .uk-offcanvas-bar {
        left: 0;
    }

    .uk-open > .uk-offcanvas-reveal {
        width: 270px;
    }

    @media (min-width: 960px) {
        .uk-open > .uk-offcanvas-reveal {
            width: 350px;
        }
    }

    .uk-offcanvas-close {
        position: absolute;
        z-index: 1000;
        top: 20px;
        right: 20px;
        padding: 5px;
    }

    .uk-offcanvas-overlay {
        width: 100vw;
        touch-action: none;
    }

    .uk-offcanvas-overlay::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: opacity 0.15s linear;
    }

    .uk-offcanvas-overlay.uk-open::before {
        opacity: 1;
    }

    .uk-offcanvas-container,
    .uk-offcanvas-page {
        overflow-x: hidden;
    }

    .uk-offcanvas-container {
        position: relative;
        left: 0;
        transition: left 0.3s ease-out;
        box-sizing: border-box;
        width: 100%;
    }

    .uk-switcher {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .uk-switcher > :not(.uk-active) {
        display: none;
    }

    .uk-switcher > * > :last-child {
        margin-bottom: 0;
    }

    .uk-leader {
        overflow: hidden;
    }

    .uk-leader-fill::after {
        display: inline-block;
        margin-left: 10px;
        width: 0;
        content: attr(data-fill);
        white-space: nowrap;
        color: #cdcdcb;
    }

    .uk-leader-fill.uk-leader-hide::after {
        display: none;
    }

    .uk-leader-fill-content::before {
        content: '.';
    }

    .uk-notification {
        position: fixed;
        top: 15px;
        left: 15px;
        z-index: 1040;
        box-sizing: border-box;
        width: 420px;
    }

    .uk-notification-bottom-right,
    .uk-notification-top-right {
        left: auto;
        right: 15px;
    }

    .uk-notification-bottom-center,
    .uk-notification-top-center {
        left: 50%;
        margin-left: -210px;
    }

    .uk-notification-bottom-center,
    .uk-notification-bottom-left,
    .uk-notification-bottom-right {
        top: auto;
        bottom: 15px;
    }

    @media (max-width: 639px) {
        .uk-notification {
            left: 15px;
            right: 15px;
            width: auto;
            margin: 0;
        }
    }

    .uk-notification-message {
        position: relative;
        padding: 20px;
        background: #2f2f2f;
        color: #cdcdcb;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
    }

    * + .uk-notification-message {
        margin-top: 10px;
    }

    .uk-notification-close {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .uk-notification-message:hover .uk-notification-close {
        display: block;
    }

    .uk-notification-message-primary {
        color: var(--shop-secondary);
        background-color: var(--shop-main);
    }

    .uk-notification-message-success {
        color: var(--shop-secondary);
        background-color: #51cb20;
    }

    .uk-notification-message-warning {
        color: var(--shop-secondary);
        background-color: #deb986;
    }

    .uk-notification-message-danger {
        color: var(--shop-secondary);
        background-color: #ed4d6e;
    }

    .uk-tooltip {
        display: none;
        position: absolute;
        z-index: 1030;
        top: 0;
        box-sizing: border-box;
        max-width: 200px;
        padding: 3px 6px;
        background: #666;
        border-radius: 2px;
        color: var(--shop-secondary);
        font-size: 12px;
    }

    .uk-tooltip.uk-active {
        display: block;
    }

    [class*='uk-tooltip-top'] {
        margin-top: -10px;
    }

    [class*='uk-tooltip-bottom'] {
        margin-top: 10px;
    }

    [class*='uk-tooltip-left'] {
        margin-left: -10px;
    }

    [class*='uk-tooltip-right'] {
        margin-left: 10px;
    }

    .uk-sortable {
        position: relative;
    }

    .uk-sortable > :last-child {
        margin-bottom: 0;
    }

    .uk-sortable-drag {
        position: fixed !important;
        z-index: 1050 !important;
        pointer-events: none;
    }

    .uk-sortable-placeholder {
        opacity: 0;
        pointer-events: none;
    }

    .uk-sortable-empty {
        min-height: 50px;
    }

    .uk-sortable-handle:hover {
        cursor: move;
    }

    .uk-countdown-number,
    .uk-countdown-separator {
        color: #fff;
    }

    .uk-countdown-number {
        font-variant-numeric: tabular-nums;
        font-size: 2rem;
        line-height: 0.8;
    }

    @media (min-width: 640px) {
        .uk-countdown-number {
            font-size: 4rem;
        }
    }

    @media (min-width: 960px) {
        .uk-countdown-number {
            font-size: 6rem;
        }
    }

    .uk-countdown-separator {
        font-size: 1rem;
        line-height: 1.6;
    }

    @media (min-width: 640px) {
        .uk-countdown-separator {
            font-size: 2rem;
        }
    }

    @media (min-width: 960px) {
        .uk-countdown-separator {
            font-size: 3rem;
        }
    }

    .uk-countdown-label {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
    }

    .uk-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .uk-grid > * {
        margin: 0;
    }

    .uk-grid > * > :last-child {
        margin-bottom: 0;
    }

    .uk-grid {
        margin-left: -20px;
    }

    .uk-grid > * {
        padding-left: 20px;
    }

    * + .uk-grid-margin,
    .uk-grid + .uk-grid,
    .uk-grid > .uk-grid-margin {
        margin-top: 20px;
    }

    @media (min-width: 1200px) {
        .uk-grid {
            margin-left: -40px;
        }

        .uk-grid > * {
            padding-left: 40px;
        }

        * + .uk-grid-margin,
        .uk-grid + .uk-grid,
        .uk-grid > .uk-grid-margin {
            margin-top: 40px;
        }
    }

    .uk-grid-column-small,
    .uk-grid-small {
        margin-left: -10px;
    }

    .uk-grid-column-small > *,
    .uk-grid-small > * {
        padding-left: 10px;
    }

    * + .uk-grid-margin-small,
    .uk-grid + .uk-grid-row-small,
    .uk-grid + .uk-grid-small,
    .uk-grid-row-small > .uk-grid-margin,
    .uk-grid-small > .uk-grid-margin {
        margin-top: 10px;
    }

    .uk-grid-column-medium,
    .uk-grid-medium {
        margin-left: -20px;
    }

    .uk-grid-column-medium > *,
    .uk-grid-medium > * {
        padding-left: 20px;
    }

    * + .uk-grid-margin-medium,
    .uk-grid + .uk-grid-medium,
    .uk-grid + .uk-grid-row-medium,
    .uk-grid-medium > .uk-grid-margin,
    .uk-grid-row-medium > .uk-grid-margin {
        margin-top: 20px;
    }

    .uk-grid-column-large,
    .uk-grid-large {
        margin-left: -40px;
    }

    .uk-grid-column-large > *,
    .uk-grid-large > * {
        padding-left: 40px;
    }

    * + .uk-grid-margin-large,
    .uk-grid + .uk-grid-large,
    .uk-grid + .uk-grid-row-large,
    .uk-grid-large > .uk-grid-margin,
    .uk-grid-row-large > .uk-grid-margin {
        margin-top: 40px;
    }

    @media (min-width: 1200px) {
        .uk-grid-column-large,
        .uk-grid-large {
            margin-left: -70px;
        }

        .uk-grid-column-large > *,
        .uk-grid-large > * {
            padding-left: 70px;
        }

        * + .uk-grid-margin-large,
        .uk-grid + .uk-grid-large,
        .uk-grid + .uk-grid-row-large,
        .uk-grid-large > .uk-grid-margin,
        .uk-grid-row-large > .uk-grid-margin {
            margin-top: 70px;
        }
    }

    .uk-grid-collapse,
    .uk-grid-column-collapse {
        margin-left: 0;
    }

    .uk-grid-collapse > *,
    .uk-grid-column-collapse > * {
        padding-left: 0;
    }

    .uk-grid + .uk-grid-collapse,
    .uk-grid + .uk-grid-row-collapse,
    .uk-grid-collapse > .uk-grid-margin,
    .uk-grid-row-collapse > .uk-grid-margin {
        margin-top: 0;
    }

    .uk-grid-divider > * {
        position: relative;
    }

    .uk-grid-divider > :not(.uk-first-column)::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        border-left: 2px solid #201f1f;
    }

    .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-top: 2px solid #201f1f;
    }

    .uk-grid-divider {
        margin-left: -40px;
    }

    .uk-grid-divider > * {
        padding-left: 40px;
    }

    .uk-grid-divider > :not(.uk-first-column)::before {
        left: 20px;
    }

    .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
        margin-top: 40px;
    }

    .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
        top: -20px;
        left: 40px;
    }

    @media (min-width: 1200px) {
        .uk-grid-divider {
            margin-left: -80px;
        }

        .uk-grid-divider > * {
            padding-left: 80px;
        }

        .uk-grid-divider > :not(.uk-first-column)::before {
            left: 40px;
        }

        .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
            margin-top: 80px;
        }

        .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
            top: -40px;
            left: 80px;
        }
    }

    .uk-grid-divider.uk-grid-column-small,
    .uk-grid-divider.uk-grid-small {
        margin-left: -20px;
    }

    .uk-grid-divider.uk-grid-column-small > *,
    .uk-grid-divider.uk-grid-small > * {
        padding-left: 20px;
    }

    .uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before,
    .uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before {
        left: 10px;
    }

    .uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin,
    .uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin {
        margin-top: 20px;
    }

    .uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
        top: -10px;
        left: 20px;
    }

    .uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
        top: -10px;
    }

    .uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
        left: 20px;
    }

    .uk-grid-divider.uk-grid-column-medium,
    .uk-grid-divider.uk-grid-medium {
        margin-left: -40px;
    }

    .uk-grid-divider.uk-grid-column-medium > *,
    .uk-grid-divider.uk-grid-medium > * {
        padding-left: 40px;
    }

    .uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before,
    .uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before {
        left: 20px;
    }

    .uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
    .uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
        margin-top: 40px;
    }

    .uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
        top: -20px;
        left: 40px;
    }

    .uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
        top: -20px;
    }

    .uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
        left: 40px;
    }

    .uk-grid-divider.uk-grid-column-large,
    .uk-grid-divider.uk-grid-large {
        margin-left: -80px;
    }

    .uk-grid-divider.uk-grid-column-large > *,
    .uk-grid-divider.uk-grid-large > * {
        padding-left: 80px;
    }

    .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before,
    .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
        left: 40px;
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
    .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
        margin-top: 80px;
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
        top: -40px;
        left: 80px;
    }

    .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
        top: -40px;
    }

    .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
        left: 80px;
    }

    @media (min-width: 1200px) {
        .uk-grid-divider.uk-grid-column-large,
        .uk-grid-divider.uk-grid-large {
            margin-left: -140px;
        }

        .uk-grid-divider.uk-grid-column-large > *,
        .uk-grid-divider.uk-grid-large > * {
            padding-left: 140px;
        }

        .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before,
        .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
            left: 70px;
        }

        .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
        .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
            margin-top: 140px;
        }

        .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
            top: -70px;
            left: 140px;
        }

        .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
            top: -70px;
        }

        .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
            left: 140px;
        }
    }

    .uk-grid-item-match,
    .uk-grid-match > * {
        display: flex;
        flex-wrap: wrap;
    }

    .uk-grid-item-match > :not([class*='uk-width']),
    .uk-grid-match > * > :not([class*='uk-width']) {
        box-sizing: border-box;
        width: 100%;
        flex: auto;
    }

    .uk-nav,
    .uk-nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .uk-nav li > a {
        display: flex;
        align-items: center;
        column-gap: 0.25em;
        text-decoration: none;
    }

    .uk-nav li > a > * {
        flex: none;
    }

    .uk-nav li > a:focus {
        outline: 0;
    }

    .uk-nav > li > a {
        padding: 5px 0;
    }

    ul.uk-nav-sub {
        padding: 5px 0 5px 15px;
    }

    .uk-nav-sub ul {
        padding-left: 15px;
    }

    .uk-nav-sub a {
        padding: 2px 0;
    }

    .uk-nav-parent-icon > .uk-parent > a::after {
        content: '';
        width: 1.6em;
        height: 1.6em;
        margin-left: auto;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23CDCDCB%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin-right: 0;
    }

    .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23CDCDCB%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-nav-header {
        padding: 5px 0;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
    }

    .uk-nav-header:not(:first-child) {
        margin-top: 20px;
    }

    .uk-nav > .uk-nav-divider {
        margin: 5px 0;
    }

    .uk-nav-default {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-nav-default > li > a {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-nav-default > li > a:focus,
    .uk-nav-default > li > a:hover {
        color: #fff;
        background-color: transparent;
    }

    .uk-nav-default > li.uk-active > a {
        color: #fff;
        background-color: transparent;
    }

    .uk-nav-default .uk-nav-header {
        color: #fff;
    }

    .uk-nav-default .uk-nav-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-nav-default .uk-nav-sub a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-nav-default .uk-nav-sub a:focus,
    .uk-nav-default .uk-nav-sub a:hover {
        color: #fff;
    }

    .uk-nav-default .uk-nav-sub li.uk-active > a {
        color: #fff;
    }

    .uk-nav-primary {
        font-weight: 300;
    }

    .uk-nav-primary > li > a {
        font-size: 22px;
        line-height: 1.6;
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        padding: 5px 0;
    }

    .uk-nav-primary > li > a:focus,
    .uk-nav-primary > li > a:hover {
        color: #fff;
    }

    .uk-nav-primary > li.uk-active > a {
        color: #fff;
    }

    .uk-nav-primary .uk-nav-header {
        color: #fff;
        padding: 5px 0;
    }

    .uk-nav-primary .uk-nav-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-nav-primary .uk-nav-sub a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-nav-primary .uk-nav-sub a:focus,
    .uk-nav-primary .uk-nav-sub a:hover {
        color: #fff;
    }

    .uk-nav-primary .uk-nav-sub li.uk-active > a {
        color: #fff;
    }

    .uk-nav-center {
        text-align: center;
    }

    .uk-nav-center li > a {
        justify-content: center;
    }

    .uk-nav-center .uk-nav-sub,
    .uk-nav-center .uk-nav-sub ul {
        padding-left: 0;
    }

    .uk-nav-center.uk-nav-parent-icon > .uk-parent > a::after {
        margin-left: 0;
    }

    .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
        margin-top: 0;
        padding-top: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-navbar {
        display: flex;
        position: relative;
    }

    .uk-navbar-container:not(.uk-navbar-transparent) {
        background: var(--shop-secondary);
    }

    .uk-navbar-container > ::after,
    .uk-navbar-container > ::before {
        display: none !important;
    }

    .uk-navbar-center,
    .uk-navbar-center-left > *,
    .uk-navbar-center-right > *,
    .uk-navbar-left,
    .uk-navbar-right {
        display: flex;
        align-items: center;
    }

    .uk-navbar-right {
        margin-left: auto;
    }

    .uk-navbar-center:only-child {
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .uk-navbar-center:not(:only-child) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
        box-sizing: border-box;
        z-index: 990;
    }

    .uk-navbar-center-left,
    .uk-navbar-center-right {
        position: absolute;
        top: 0;
    }

    .uk-navbar-center-left {
        right: 100%;
    }

    .uk-navbar-center-right {
        left: 100%;
    }

    [class*='uk-navbar-center-'] {
        width: max-content;
        box-sizing: border-box;
    }

    .uk-navbar-nav {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .uk-navbar-center:only-child,
    .uk-navbar-left,
    .uk-navbar-right {
        flex-wrap: wrap;
    }

    .uk-navbar-item,
    .uk-navbar-nav > li > a,
    .uk-navbar-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.25em;
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 16px;
        font-family: inherit;
        text-decoration: none;
        min-height: unset;
    }

    .uk-navbar-nav > li > a {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        position: relative;
        z-index: 1;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-navbar-nav > li > a::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        height: 1px;
        background-color: transparent;
        transition: 0.1s ease-in-out;
        transition-property: background-color, border-color, box-shadow, height, left, right, opacity;
        left: 0;
        right: 0;
    }

    .uk-navbar-primary .uk-navbar-nav > li > a {
        font-size: 22px;
    }

    .uk-navbar-primary .uk-navbar-nav > li > a {
        font-weight: 400;
    }

    .uk-navbar-nav > li:hover > a,
    .uk-navbar-nav > li > a.uk-open,
    .uk-navbar-nav > li > a:focus {
        color: #fff;
        outline: 0;
    }

    .uk-navbar-nav > li > a:active {
        color: #fff;
    }

    .uk-navbar-nav > li > a:active::before {
        height: 2px;
    }

    .uk-navbar-nav > li.uk-active > a {
        color: #fff;
    }

    .uk-navbar-nav > li.uk-active > a::before {
        background-color: var(--shop-main);
        height: 2px;
    }

    .uk-navbar-item {
        color: #cdcdcb;
    }

    .uk-navbar-item > :last-child {
        margin-bottom: 0;
    }

    .uk-navbar-toggle {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-navbar-toggle.uk-open,
    .uk-navbar-toggle:focus,
    .uk-navbar-toggle:hover {
        color: #fff;
        outline: 0;
        text-decoration: none;
    }

    .uk-navbar-subtitle {
        font-size: 11px;
    }

    .uk-navbar-sticky {
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-navbar-dropdown {
        display: none;
        position: absolute;
        z-index: 1020;
        box-sizing: border-box;
        width: 200px;
        padding: 25px;
        background: var(--shop-secondary);
        color: #cdcdcb;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-navbar-dropdown.uk-open {
        display: block;
    }

    [class*='uk-navbar-dropdown-top'] {
        margin-top: 0;
    }

    [class*='uk-navbar-dropdown-bottom'] {
        margin-top: 0;
    }

    [class*='uk-navbar-dropdown-left'] {
        margin-left: 0;
    }

    [class*='uk-navbar-dropdown-right'] {
        margin-left: 0;
    }

    .uk-navbar-dropdown-grid {
        margin-left: -50px;
    }

    .uk-navbar-dropdown-grid > * {
        padding-left: 50px;
    }

    .uk-navbar-dropdown-grid > .uk-grid-margin {
        margin-top: 50px;
    }

    .uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
        width: 100% !important;
    }

    .uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
        width: 400px;
    }

    .uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
        width: 600px;
    }

    .uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
        width: 800px;
    }

    .uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
        width: 1000px;
    }

    .uk-navbar-dropdown-dropbar {
        margin-top: 0;
        margin-bottom: 0;
        background: 0 0;
        box-shadow: none;
    }

    .uk-navbar-dropdown-nav {
        margin-left: 0;
        margin-right: 0;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-navbar-dropdown-nav > li > a {
        color: rgba(255, 255, 255, 0.5);
        padding: 5px 0;
    }

    .uk-navbar-dropdown-nav > li > a:focus,
    .uk-navbar-dropdown-nav > li > a:hover {
        color: #fff;
    }

    .uk-navbar-dropdown-nav > li.uk-active > a {
        color: #fff;
    }

    .uk-navbar-dropdown-nav .uk-nav-header {
        color: #fff;
        padding: 5px 0;
    }

    .uk-navbar-dropdown-nav .uk-nav-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .uk-navbar-dropdown-nav .uk-nav-sub a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-navbar-dropdown-nav .uk-nav-sub a:focus,
    .uk-navbar-dropdown-nav .uk-nav-sub a:hover {
        color: #fff;
    }

    .uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
        color: #fff;
    }

    .uk-navbar-dropbar {
        background: var(--shop-secondary);
    }

    .uk-navbar-dropbar-slide {
        position: absolute;
        z-index: 980;
        left: 0;
        right: 0;
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.03);
    }

    .uk-navbar-container > .uk-container .uk-navbar-left {
        margin-left: -15px;
        margin-right: -15px;
    }

    .uk-navbar-container > .uk-container .uk-navbar-right {
        margin-right: -15px;
    }

    .uk-navbar-dropdown-nav .uk-nav-sub {
        padding-left: 15px;
    }

    .uk-navbar-dropdown-grid > * {
        position: relative;
    }

    .uk-navbar-dropdown-grid > :not(.uk-first-column)::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 25px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-navbar-dropdown-grid.uk-grid-stack > .uk-grid-margin::before {
        content: '';
        position: absolute;
        top: -25px;
        left: 50px;
        right: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-subnav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -20px;
        padding: 0;
        list-style: none;
    }

    .uk-subnav > * {
        flex: none;
        padding-left: 20px;
        position: relative;
    }

    .uk-subnav > * > :first-child {
        display: flex;
        align-items: center;
        column-gap: 0.25em;
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-subnav > * > a:focus,
    .uk-subnav > * > a:hover {
        color: #fff;
        text-decoration: none;
        outline: 0;
    }

    .uk-subnav > .uk-active > a {
        color: #fff;
    }

    .uk-subnav-divider {
        margin-left: -41px;
    }

    .uk-subnav-divider > * {
        display: flex;
        align-items: center;
    }

    .uk-subnav-divider > ::before {
        content: '';
        height: 0.9em;
        margin-left: 0;
        margin-right: 20px;
        border-left: 1px solid transparent;
    }

    .uk-subnav-divider > :nth-child(n + 2):not(.uk-first-column)::before {
        border-left-color: rgba(255, 255, 255, 0.1);
    }

    .uk-subnav-pill > * > :first-child {
        padding: 8px 20px;
        background: 0 0;
        color: rgba(255, 255, 255, 0.5);
        border-radius: 500px;
    }

    .uk-subnav-pill > * > a:focus,
    .uk-subnav-pill > * > a:hover {
        background-color: rgba(0, 0, 0, 0.74);
        color: #fff;
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .uk-subnav-pill > * > a:active {
        background-color: rgba(0, 0, 0, 0.74);
        color: #fff;
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-subnav-pill > .uk-active > a {
        background-color: var(--shop-main);
        color: #fff;
    }

    .uk-subnav > .uk-disabled > a {
        color: #939fa2;
    }

    .uk-breadcrumb {
        padding: 0;
        list-style: none;
    }

    .uk-breadcrumb > * {
        display: contents;
    }

    .uk-breadcrumb > * > * {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .uk-breadcrumb > * > :focus,
    .uk-breadcrumb > * > :hover {
        color: #fff;
        text-decoration: none;
    }

    .uk-breadcrumb > .uk-disabled > * {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-breadcrumb > :last-child > a:not([href]),
    .uk-breadcrumb > :last-child > span {
        color: #fff;
    }

    .uk-breadcrumb > :nth-child(n + 2):not(.uk-first-column)::before {
        content: '/';
        display: inline-block;
        margin: 0 20px 0 calc(20px - 4px);
        font-size: 11px;
        color: rgba(242, 242, 242, 0.1);
    }

    .uk-pagination {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        padding: 0;
        list-style: none;
    }

    .uk-pagination > * {
        flex: none;
        padding-left: 10px;
        position: relative;
    }

    .uk-pagination > * > * {
        display: block;
        padding: 3px 6px;
        color: rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        min-width: 26px;
        background: 0 0;
        line-height: 20px;
        text-align: center;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0;
        border-radius: 500px;
    }

    .uk-pagination > * > :focus,
    .uk-pagination > * > :hover {
        color: #fff;
        text-decoration: none;
        background-color: var(--shop-secondary);
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-pagination > .uk-active > * {
        color: #fff;
        background-color: transparent;
    }

    .uk-pagination > .uk-disabled > * {
        color: rgba(255, 255, 255, 0.5);
        background-color: transparent;
    }

    .uk-tab {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        padding: 0;
        list-style: none;
        position: relative;
    }

    .uk-tab::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .uk-tab > * {
        flex: none;
        padding-left: 20px;
        position: relative;
    }

    .uk-tab > * > a {
        display: flex;
        align-items: center;
        column-gap: 0.25em;
        justify-content: center;
        padding: 10px 20px;
        color: rgba(255, 255, 255, 0.5);
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        font-size: 11px;
        line-height: 20px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 1px solid transparent;
    }

    .uk-tab > * > a:focus,
    .uk-tab > * > a:hover {
        color: #fff;
        text-decoration: none;
        border-color: transparent;
    }

    .uk-tab > .uk-active > a {
        color: #fff;
        border-color: var(--shop-main);
    }

    .uk-tab > .uk-disabled > a {
        color: rgba(255, 255, 255, 0.5);
    }

    .uk-tab-bottom::before {
        top: 0;
        bottom: auto;
    }

    .uk-tab-bottom > * > a {
        border-top: 1px solid transparent;
        border-bottom: 0 none;
    }

    .uk-tab-left,
    .uk-tab-right {
        flex-direction: column;
        margin-left: 0;
    }

    .uk-tab-left > *,
    .uk-tab-right > * {
        padding-left: 0;
    }

    .uk-tab-left::before {
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: none;
    }

    .uk-tab-right::before {
        top: 0;
        bottom: 0;
        left: 0;
        right: auto;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: none;
    }

    .uk-tab-left > * > a {
        text-align: left;
        padding: 10px 20px;
        border-right: 1px solid transparent;
        border-bottom: 0 none;
    }

    .uk-tab-right > * > a {
        text-align: left;
        padding: 10px 20px;
        border-left: 1px solid transparent;
        border-bottom: 0 none;
    }

    .uk-tab .uk-dropdown {
        margin-left: 40px;
    }

    .uk-slidenav {
        padding: 5px 10px;
        color: rgba(205, 205, 203, 0.5);
        background: 0 0;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-slidenav:focus,
    .uk-slidenav:hover {
        color: rgba(205, 205, 203, 0.9);
        outline: 0;
        background-color: transparent;
    }

    .uk-slidenav:active {
        color: rgba(205, 205, 203, 0.5);
        background-color: transparent;
    }

    .uk-slidenav-large {
        padding: 10px 10px;
    }

    .uk-slidenav-container {
        display: flex;
    }

    .uk-dotnav {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: -14px;
    }

    .uk-dotnav > * {
        flex: none;
        padding-left: 14px;
    }

    .uk-dotnav > * > * {
        display: block;
        box-sizing: border-box;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(205, 205, 203, 0.4);
        text-indent: 100%;
        overflow: hidden;
        white-space: nowrap;
        transition: 0.2s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
    }

    .uk-dotnav > * > :focus,
    .uk-dotnav > * > :hover {
        background-color: var(--shop-main);
        outline: 0;
    }

    .uk-dotnav > * > :active {
        background-color: rgba(205, 205, 203, 0.2);
    }

    .uk-dotnav > .uk-active > * {
        background-color: var(--shop-main);
    }

    .uk-dotnav-vertical {
        flex-direction: column;
        margin-left: 0;
        margin-top: -14px;
    }

    .uk-dotnav-vertical > * {
        padding-left: 0;
        padding-top: 14px;
    }

    .uk-thumbnav {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: -15px;
    }

    .uk-thumbnav > * {
        padding-left: 15px;
    }

    .uk-thumbnav > * > * {
        display: inline-block;
        position: relative;
    }

    .uk-thumbnav > * > ::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(14, 14, 14, 0.4);
        transition: 0.1s ease-in-out;
        transition-property: background-color, border-color, box-shadow;
    }

    .uk-thumbnav > * > :focus,
    .uk-thumbnav > * > :hover {
        outline: 0;
    }

    .uk-thumbnav > * > :focus::after,
    .uk-thumbnav > * > :hover::after {
        background-color: transparent;
    }

    .uk-thumbnav > .uk-active > ::after {
        background-color: transparent;
    }

    .uk-thumbnav-vertical {
        flex-direction: column;
        margin-left: 0;
        margin-top: -15px;
    }

    .uk-thumbnav-vertical > * {
        padding-left: 0;
        padding-top: 15px;
    }

    .uk-iconnav {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: -10px;
    }

    .uk-iconnav > * {
        padding-left: 10px;
    }

    .uk-iconnav > * > a {
        display: flex;
        align-items: center;
        column-gap: 0.25em;
        line-height: 0;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
        transition: 0.1s ease-in-out;
        transition-property: color, background-color, border-color, box-shadow;
        font-size: 16px;
    }

    .uk-iconnav > * > a:focus,
    .uk-iconnav > * > a:hover {
        color: #fff;
        outline: 0;
    }

    .uk-iconnav > .uk-active > a {
        color: #fff;
    }

    .uk-iconnav-vertical {
        flex-direction: column;
        margin-left: 0;
        margin-top: -10px;
    }

    .uk-iconnav-vertical > * {
        padding-left: 0;
        padding-top: 10px;
    }

    .uk-lightbox {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1010;
        background: #000;
        opacity: 0;
        transition: opacity 0.15s linear;
        touch-action: pinch-zoom;
    }

    .uk-lightbox.uk-open {
        display: block;
        opacity: 1;
    }

    .uk-lightbox-page {
        overflow: hidden;
    }

    .uk-lightbox-items > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
        will-change: transform, opacity;
    }

    .uk-lightbox-items > * > * {
        max-width: 100vw;
        max-height: 100vh;
    }

    .uk-lightbox-items > :focus {
        outline: 0;
    }

    .uk-lightbox-items > * > :not(iframe) {
        width: auto;
        height: auto;
    }

    .uk-lightbox-items > .uk-active {
        display: flex;
    }

    .uk-lightbox-toolbar {
        padding: 10px 10px;
        background: rgba(0, 0, 0, 0.3);
        color: rgba(255, 255, 255, 0.7);
    }

    .uk-lightbox-toolbar > * {
        color: rgba(255, 255, 255, 0.7);
    }

    .uk-lightbox-toolbar-icon {
        padding: 5px;
        color: rgba(255, 255, 255, 0.7);
    }

    .uk-lightbox-toolbar-icon:hover {
        color: #fff;
    }

    .uk-lightbox-button {
        box-sizing: border-box;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.3);
        color: rgba(255, 255, 255, 0.7);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .uk-lightbox-button:focus,
    .uk-lightbox-button:hover {
        color: #fff;
    }

    .uk-lightbox-caption:empty {
        display: none;
    }

    .uk-lightbox-iframe {
        width: 80%;
        height: 80%;
    }

    [class*='uk-animation-'] {
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;
    }

    .uk-animation-fade {
        animation-name: uk-fade;
        animation-duration: 0.8s;
        animation-timing-function: linear;
    }

    .uk-animation-scale-up {
        animation-name: uk-fade-scale-02;
    }

    .uk-animation-scale-down {
        animation-name: uk-fade-scale-18;
    }

    .uk-animation-slide-top {
        animation-name: uk-fade-top;
    }

    .uk-animation-slide-bottom {
        animation-name: uk-fade-bottom;
    }

    .uk-animation-slide-left {
        animation-name: uk-fade-left;
    }

    .uk-animation-slide-right {
        animation-name: uk-fade-right;
    }

    .uk-animation-slide-top-small {
        animation-name: uk-fade-top-small;
    }

    .uk-animation-slide-bottom-small {
        animation-name: uk-fade-bottom-small;
    }

    .uk-animation-slide-left-small {
        animation-name: uk-fade-left-small;
    }

    .uk-animation-slide-right-small {
        animation-name: uk-fade-right-small;
    }

    .uk-animation-slide-top-medium {
        animation-name: uk-fade-top-medium;
    }

    .uk-animation-slide-bottom-medium {
        animation-name: uk-fade-bottom-medium;
    }

    .uk-animation-slide-left-medium {
        animation-name: uk-fade-left-medium;
    }

    .uk-animation-slide-right-medium {
        animation-name: uk-fade-right-medium;
    }

    .uk-animation-kenburns {
        animation-name: uk-scale-kenburns;
        animation-duration: 15s;
    }

    .uk-animation-shake {
        animation-name: uk-shake;
    }

    .uk-animation-stroke {
        animation-name: uk-stroke;
        stroke-dasharray: var(--uk-animation-stroke);
        animation-duration: 2s;
    }

    .uk-animation-reverse {
        animation-direction: reverse;
        animation-timing-function: ease-in;
    }

    .uk-animation-fast {
        animation-duration: 0.1s;
    }

    .uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
        animation-name: none;
    }

    .uk-animation-toggle {
        -webkit-tap-highlight-color: transparent;
    }

    .uk-animation-toggle:focus {
        outline: 0;
    }

    @keyframes uk-fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes uk-fade-top {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-bottom {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-left {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-right {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-top-small {
        0% {
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-bottom-small {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-left-small {
        0% {
            opacity: 0;
            transform: translateX(-10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-right-small {
        0% {
            opacity: 0;
            transform: translateX(10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-top-medium {
        0% {
            opacity: 0;
            transform: translateY(-50px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-bottom-medium {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes uk-fade-left-medium {
        0% {
            opacity: 0;
            transform: translateX(-50px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-right-medium {
        0% {
            opacity: 0;
            transform: translateX(50px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes uk-fade-scale-02 {
        0% {
            opacity: 0;
            transform: scale(0.2);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes uk-fade-scale-18 {
        0% {
            opacity: 0;
            transform: scale(1.8);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes uk-scale-kenburns {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }

    @keyframes uk-shake {
        0%,
        100% {
            transform: translateX(0);
        }
        10% {
            transform: translateX(-9px);
        }
        20% {
            transform: translateX(8px);
        }
        30% {
            transform: translateX(-7px);
        }
        40% {
            transform: translateX(6px);
        }
        50% {
            transform: translateX(-5px);
        }
        60% {
            transform: translateX(4px);
        }
        70% {
            transform: translateX(-3px);
        }
        80% {
            transform: translateX(2px);
        }
        90% {
            transform: translateX(-1px);
        }
    }

    @keyframes uk-stroke {
        0% {
            stroke-dashoffset: var(--uk-animation-stroke);
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    [class*='uk-child-width'] > * {
        box-sizing: border-box;
        width: 100%;
    }

    .uk-child-width-1-2 > * {
        width: 50%;
    }

    .uk-child-width-1-3 > * {
        width: calc(100% * 1 / 3.001);
    }

    .uk-child-width-1-4 > * {
        width: 25%;
    }

    .uk-child-width-1-5 > * {
        width: 20%;
    }

    .uk-child-width-1-6 > * {
        width: calc(100% * 1 / 6.001);
    }

    .uk-child-width-auto > * {
        width: auto;
    }

    .uk-child-width-expand > :not([class*='uk-width']) {
        flex: 1;
        min-width: 1px;
    }

    @media (min-width: 640px) {
        .uk-child-width-1-1\@s > * {
            width: 100%;
        }

        .uk-child-width-1-2\@s > * {
            width: 50%;
        }

        .uk-child-width-1-3\@s > * {
            width: calc(100% * 1 / 3.001);
        }

        .uk-child-width-1-4\@s > * {
            width: 25%;
        }

        .uk-child-width-1-5\@s > * {
            width: 20%;
        }

        .uk-child-width-1-6\@s > * {
            width: calc(100% * 1 / 6.001);
        }

        .uk-child-width-auto\@s > * {
            width: auto;
        }

        .uk-child-width-expand\@s > :not([class*='uk-width']) {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 960px) {
        .uk-child-width-1-1\@m > * {
            width: 100%;
        }

        .uk-child-width-1-2\@m > * {
            width: 50%;
        }

        .uk-child-width-1-3\@m > * {
            width: calc(100% * 1 / 3.001);
        }

        .uk-child-width-1-4\@m > * {
            width: 25%;
        }

        .uk-child-width-1-5\@m > * {
            width: 20%;
        }

        .uk-child-width-1-6\@m > * {
            width: calc(100% * 1 / 6.001);
        }

        .uk-child-width-auto\@m > * {
            width: auto;
        }

        .uk-child-width-expand\@m > :not([class*='uk-width']) {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 1200px) {
        .uk-child-width-1-1\@l > * {
            width: 100%;
        }

        .uk-child-width-1-2\@l > * {
            width: 50%;
        }

        .uk-child-width-1-3\@l > * {
            width: calc(100% * 1 / 3.001);
        }

        .uk-child-width-1-4\@l > * {
            width: 25%;
        }

        .uk-child-width-1-5\@l > * {
            width: 20%;
        }

        .uk-child-width-1-6\@l > * {
            width: calc(100% * 1 / 6.001);
        }

        .uk-child-width-auto\@l > * {
            width: auto;
        }

        .uk-child-width-expand\@l > :not([class*='uk-width']) {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 1600px) {
        .uk-child-width-1-1\@xl > * {
            width: 100%;
        }

        .uk-child-width-1-2\@xl > * {
            width: 50%;
        }

        .uk-child-width-1-3\@xl > * {
            width: calc(100% * 1 / 3.001);
        }

        .uk-child-width-1-4\@xl > * {
            width: 25%;
        }

        .uk-child-width-1-5\@xl > * {
            width: 20%;
        }

        .uk-child-width-1-6\@xl > * {
            width: calc(100% * 1 / 6.001);
        }

        .uk-child-width-auto\@xl > * {
            width: auto;
        }

        .uk-child-width-expand\@xl > :not([class*='uk-width']) {
            flex: 1;
            min-width: 1px;
        }
    }

    [class*='uk-width'] {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
    }

    .uk-width-1-2 {
        width: 50%;
    }

    .uk-width-1-3 {
        width: calc(100% * 1 / 3.001);
    }

    .uk-width-2-3 {
        width: calc(100% * 2 / 3.001);
    }

    .uk-width-1-4 {
        width: 25%;
    }

    .uk-width-3-4 {
        width: 75%;
    }

    .uk-width-1-5 {
        width: 20%;
    }

    .uk-width-2-5 {
        width: 40%;
    }

    .uk-width-3-5 {
        width: 60%;
    }

    .uk-width-4-5 {
        width: 80%;
    }

    .uk-width-1-6 {
        width: calc(100% * 1 / 6.001);
    }

    .uk-width-5-6 {
        width: calc(100% * 5 / 6.001);
    }

    .uk-width-small {
        width: 150px;
    }

    .uk-width-medium {
        width: 300px;
    }

    .uk-width-large {
        width: 450px;
    }

    .uk-width-xlarge {
        width: 600px;
    }

    .uk-width-2xlarge {
        width: 750px;
    }

    .uk-width-auto {
        width: auto;
    }

    .uk-width-expand {
        flex: 1;
        min-width: 1px;
    }

    @media (min-width: 640px) {
        .uk-width-1-1\@s {
            width: 100%;
        }

        .uk-width-1-2\@s {
            width: 50%;
        }

        .uk-width-1-3\@s {
            width: calc(100% * 1 / 3.001);
        }

        .uk-width-2-3\@s {
            width: calc(100% * 2 / 3.001);
        }

        .uk-width-1-4\@s {
            width: 25%;
        }

        .uk-width-3-4\@s {
            width: 75%;
        }

        .uk-width-1-5\@s {
            width: 20%;
        }

        .uk-width-2-5\@s {
            width: 40%;
        }

        .uk-width-3-5\@s {
            width: 60%;
        }

        .uk-width-4-5\@s {
            width: 80%;
        }

        .uk-width-1-6\@s {
            width: calc(100% * 1 / 6.001);
        }

        .uk-width-5-6\@s {
            width: calc(100% * 5 / 6.001);
        }

        .uk-width-small\@s {
            width: 150px;
        }

        .uk-width-medium\@s {
            width: 300px;
        }

        .uk-width-large\@s {
            width: 450px;
        }

        .uk-width-xlarge\@s {
            width: 600px;
        }

        .uk-width-2xlarge\@s {
            width: 750px;
        }

        .uk-width-auto\@s {
            width: auto;
        }

        .uk-width-expand\@s {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 960px) {
        .uk-width-1-1\@m {
            width: 100%;
        }

        .uk-width-1-2\@m {
            width: 50%;
        }

        .uk-width-1-3\@m {
            width: calc(100% * 1 / 3.001);
        }

        .uk-width-2-3\@m {
            width: calc(100% * 2 / 3.001);
        }

        .uk-width-1-4\@m {
            width: 25%;
        }

        .uk-width-3-4\@m {
            width: 75%;
        }

        .uk-width-1-5\@m {
            width: 20%;
        }

        .uk-width-2-5\@m {
            width: 40%;
        }

        .uk-width-3-5\@m {
            width: 60%;
        }

        .uk-width-4-5\@m {
            width: 80%;
        }

        .uk-width-1-6\@m {
            width: calc(100% * 1 / 6.001);
        }

        .uk-width-5-6\@m {
            width: calc(100% * 5 / 6.001);
        }

        .uk-width-small\@m {
            width: 150px;
        }

        .uk-width-medium\@m {
            width: 300px;
        }

        .uk-width-large\@m {
            width: 450px;
        }

        .uk-width-xlarge\@m {
            width: 600px;
        }

        .uk-width-2xlarge\@m {
            width: 750px;
        }

        .uk-width-auto\@m {
            width: auto;
        }

        .uk-width-expand\@m {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 1200px) {
        .uk-width-1-1\@l {
            width: 100%;
        }

        .uk-width-1-2\@l {
            width: 50%;
        }

        .uk-width-1-3\@l {
            width: calc(100% * 1 / 3.001);
        }

        .uk-width-2-3\@l {
            width: calc(100% * 2 / 3.001);
        }

        .uk-width-1-4\@l {
            width: 25%;
        }

        .uk-width-3-4\@l {
            width: 75%;
        }

        .uk-width-1-5\@l {
            width: 20%;
        }

        .uk-width-2-5\@l {
            width: 40%;
        }

        .uk-width-3-5\@l {
            width: 60%;
        }

        .uk-width-4-5\@l {
            width: 80%;
        }

        .uk-width-1-6\@l {
            width: calc(100% * 1 / 6.001);
        }

        .uk-width-5-6\@l {
            width: calc(100% * 5 / 6.001);
        }

        .uk-width-small\@l {
            width: 150px;
        }

        .uk-width-medium\@l {
            width: 300px;
        }

        .uk-width-large\@l {
            width: 450px;
        }

        .uk-width-xlarge\@l {
            width: 600px;
        }

        .uk-width-2xlarge\@l {
            width: 750px;
        }

        .uk-width-auto\@l {
            width: auto;
        }

        .uk-width-expand\@l {
            flex: 1;
            min-width: 1px;
        }
    }

    @media (min-width: 1600px) {
        .uk-width-1-1\@xl {
            width: 100%;
        }

        .uk-width-1-2\@xl {
            width: 50%;
        }

        .uk-width-1-3\@xl {
            width: calc(100% * 1 / 3.001);
        }

        .uk-width-2-3\@xl {
            width: calc(100% * 2 / 3.001);
        }

        .uk-width-1-4\@xl {
            width: 25%;
        }

        .uk-width-3-4\@xl {
            width: 75%;
        }

        .uk-width-1-5\@xl {
            width: 20%;
        }

        .uk-width-2-5\@xl {
            width: 40%;
        }

        .uk-width-3-5\@xl {
            width: 60%;
        }

        .uk-width-4-5\@xl {
            width: 80%;
        }

        .uk-width-1-6\@xl {
            width: calc(100% * 1 / 6.001);
        }

        .uk-width-5-6\@xl {
            width: calc(100% * 5 / 6.001);
        }

        .uk-width-small\@xl {
            width: 150px;
        }

        .uk-width-medium\@xl {
            width: 300px;
        }

        .uk-width-large\@xl {
            width: 450px;
        }

        .uk-width-xlarge\@xl {
            width: 600px;
        }

        .uk-width-2xlarge\@xl {
            width: 750px;
        }

        .uk-width-auto\@xl {
            width: auto;
        }

        .uk-width-expand\@xl {
            flex: 1;
            min-width: 1px;
        }
    }

    [class*='uk-height'] {
        box-sizing: border-box;
    }

    .uk-height-1-1 {
        height: 100%;
    }

    .uk-height-viewport {
        min-height: 100vh;
    }

    .uk-height-small {
        height: 150px;
    }

    .uk-height-medium {
        height: 300px;
    }

    .uk-height-large {
        height: 450px;
    }

    .uk-height-max-small {
        max-height: 150px;
    }

    .uk-height-max-medium {
        max-height: 300px;
    }

    .uk-height-max-large {
        max-height: 450px;
    }

    .uk-text-lead {
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
        font-weight: 300;
    }

    .uk-text-meta {
        font-size: 14px;
        line-height: 1.4;
        color: #959292;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .uk-text-meta > a {
        color: #959292;
    }

    .uk-text-meta > a:hover {
        color: #cdcdcb;
        text-decoration: none;
    }

    .uk-text-small {
        font-size: 12px;
        line-height: 1.4;
    }

    .uk-text-large {
        font-size: 22px;
        line-height: 1.5;
    }

    .uk-text-default {
        font-size: 16px;
        line-height: 1.6;
    }

    .uk-text-light {
        font-weight: 300;
    }

    .uk-text-normal {
        font-weight: 400;
    }

    .uk-text-bold {
        font-weight: 700;
    }

    .uk-text-lighter {
        font-weight: lighter;
    }

    .uk-text-bolder {
        font-weight: bolder;
    }

    .uk-text-italic {
        font-style: italic;
    }

    .uk-text-capitalize {
        text-transform: capitalize !important;
    }

    .uk-text-uppercase {
        text-transform: uppercase !important;
    }

    .uk-text-lowercase {
        text-transform: lowercase !important;
    }

    .uk-text-decoration-none {
        text-decoration: none !important;
    }

    .uk-text-muted {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-text-emphasis {
        color: #fff !important;
    }

    .uk-text-primary {
        color: var(--shop-main) !important;
    }

    .uk-text-secondary {
        color: rgba(0, 0, 0, 0.74) !important;
    }

    .uk-text-success {
        color: #51cb20 !important;
    }

    .uk-text-warning {
        color: #deb986 !important;
    }

    .uk-text-danger {
        color: #ed4d6e !important;
    }

    .uk-text-background {
        -webkit-background-clip: text;
        display: inline-block;
        color: var(--shop-main) !important;
    }

    @supports (-webkit-background-clip: text) {
        .uk-text-background {
            background-color: var(--shop-main);
            color: transparent !important;
        }
    }

    .uk-text-left {
        text-align: left !important;
    }

    .uk-text-right {
        text-align: right !important;
    }

    .uk-text-center {
        text-align: center !important;
    }

    .uk-text-justify {
        text-align: justify !important;
    }

    @media (min-width: 640px) {
        .uk-text-left\@s {
            text-align: left !important;
        }

        .uk-text-right\@s {
            text-align: right !important;
        }

        .uk-text-center\@s {
            text-align: center !important;
        }
    }

    @media (min-width: 960px) {
        .uk-text-left\@m {
            text-align: left !important;
        }

        .uk-text-right\@m {
            text-align: right !important;
        }

        .uk-text-center\@m {
            text-align: center !important;
        }
    }

    @media (min-width: 1200px) {
        .uk-text-left\@l {
            text-align: left !important;
        }

        .uk-text-right\@l {
            text-align: right !important;
        }

        .uk-text-center\@l {
            text-align: center !important;
        }
    }

    @media (min-width: 1600px) {
        .uk-text-left\@xl {
            text-align: left !important;
        }

        .uk-text-right\@xl {
            text-align: right !important;
        }

        .uk-text-center\@xl {
            text-align: center !important;
        }
    }

    .uk-text-top {
        vertical-align: top !important;
    }

    .uk-text-middle {
        vertical-align: middle !important;
    }

    .uk-text-bottom {
        vertical-align: bottom !important;
    }

    .uk-text-baseline {
        vertical-align: baseline !important;
    }

    .uk-text-nowrap {
        white-space: nowrap;
    }

    .uk-text-truncate {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.uk-text-truncate,
    th.uk-text-truncate {
        max-width: 0;
    }

    .uk-text-break {
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    td.uk-text-break,
    th.uk-text-break {
        word-break: break-all;
    }

    [class*='uk-column-'] {
        column-gap: 20px;
    }

    @media (min-width: 1200px) {
        [class*='uk-column-'] {
            column-gap: 40px;
        }
    }

    [class*='uk-column-'] img {
        transform: translate3d(0, 0, 0);
    }

    .uk-column-divider {
        column-rule: 1px solid rgba(255, 255, 255, 0.1);
        column-gap: 40px;
    }

    @media (min-width: 1200px) {
        .uk-column-divider {
            column-gap: 80px;
        }
    }

    .uk-column-1-2 {
        column-count: 2;
    }

    .uk-column-1-3 {
        column-count: 3;
    }

    .uk-column-1-4 {
        column-count: 4;
    }

    .uk-column-1-5 {
        column-count: 5;
    }

    .uk-column-1-6 {
        column-count: 6;
    }

    @media (min-width: 640px) {
        .uk-column-1-2\@s {
            column-count: 2;
        }

        .uk-column-1-3\@s {
            column-count: 3;
        }

        .uk-column-1-4\@s {
            column-count: 4;
        }

        .uk-column-1-5\@s {
            column-count: 5;
        }

        .uk-column-1-6\@s {
            column-count: 6;
        }
    }

    @media (min-width: 960px) {
        .uk-column-1-2\@m {
            column-count: 2;
        }

        .uk-column-1-3\@m {
            column-count: 3;
        }

        .uk-column-1-4\@m {
            column-count: 4;
        }

        .uk-column-1-5\@m {
            column-count: 5;
        }

        .uk-column-1-6\@m {
            column-count: 6;
        }
    }

    @media (min-width: 1200px) {
        .uk-column-1-2\@l {
            column-count: 2;
        }

        .uk-column-1-3\@l {
            column-count: 3;
        }

        .uk-column-1-4\@l {
            column-count: 4;
        }

        .uk-column-1-5\@l {
            column-count: 5;
        }

        .uk-column-1-6\@l {
            column-count: 6;
        }
    }

    @media (min-width: 1600px) {
        .uk-column-1-2\@xl {
            column-count: 2;
        }

        .uk-column-1-3\@xl {
            column-count: 3;
        }

        .uk-column-1-4\@xl {
            column-count: 4;
        }

        .uk-column-1-5\@xl {
            column-count: 5;
        }

        .uk-column-1-6\@xl {
            column-count: 6;
        }
    }

    .uk-column-span {
        column-span: all;
    }

    .uk-cover {
        max-width: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    iframe.uk-cover {
        pointer-events: none;
    }

    .uk-cover-container {
        overflow: hidden;
        position: relative;
    }

    .uk-background-default {
        background-color: var(--shop-secondary);
    }

    .uk-background-muted {
        background-color: #2f2f2f;
    }

    .uk-background-primary {
        background-color: var(--shop-main);
    }

    .uk-background-secondary {
        background-color: rgba(0, 0, 0, 0.74);
    }

    .uk-background-contain,
    .uk-background-cover,
    .uk-background-height-1-1,
    .uk-background-width-1-1 {
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    .uk-background-cover {
        background-size: cover;
    }

    .uk-background-contain {
        background-size: contain;
    }

    .uk-background-width-1-1 {
        background-size: 100%;
    }

    .uk-background-height-1-1 {
        background-size: auto 100%;
    }

    .uk-background-top-left {
        background-position: 0 0;
    }

    .uk-background-top-center {
        background-position: 50% 0;
    }

    .uk-background-top-right {
        background-position: 100% 0;
    }

    .uk-background-center-left {
        background-position: 0 50%;
    }

    .uk-background-center-center {
        background-position: 50% 50%;
    }

    .uk-background-center-right {
        background-position: 100% 50%;
    }

    .uk-background-bottom-left {
        background-position: 0 100%;
    }

    .uk-background-bottom-center {
        background-position: 50% 100%;
    }

    .uk-background-bottom-right {
        background-position: 100% 100%;
    }

    .uk-background-norepeat {
        background-repeat: no-repeat;
    }

    .uk-background-fixed {
        background-attachment: fixed;
        backface-visibility: hidden;
    }

    @media (pointer: coarse) {
        .uk-background-fixed {
            background-attachment: scroll;
        }
    }

    @media (max-width: 639px) {
        .uk-background-image\@s {
            background-image: none !important;
        }
    }

    @media (max-width: 959px) {
        .uk-background-image\@m {
            background-image: none !important;
        }
    }

    @media (max-width: 1199px) {
        .uk-background-image\@l {
            background-image: none !important;
        }
    }

    @media (max-width: 1599px) {
        .uk-background-image\@xl {
            background-image: none !important;
        }
    }

    .uk-background-blend-multiply {
        background-blend-mode: multiply;
    }

    .uk-background-blend-screen {
        background-blend-mode: screen;
    }

    .uk-background-blend-overlay {
        background-blend-mode: overlay;
    }

    .uk-background-blend-darken {
        background-blend-mode: darken;
    }

    .uk-background-blend-lighten {
        background-blend-mode: lighten;
    }

    .uk-background-blend-color-dodge {
        background-blend-mode: color-dodge;
    }

    .uk-background-blend-color-burn {
        background-blend-mode: color-burn;
    }

    .uk-background-blend-hard-light {
        background-blend-mode: hard-light;
    }

    .uk-background-blend-soft-light {
        background-blend-mode: soft-light;
    }

    .uk-background-blend-difference {
        background-blend-mode: difference;
    }

    .uk-background-blend-exclusion {
        background-blend-mode: exclusion;
    }

    .uk-background-blend-hue {
        background-blend-mode: hue;
    }

    .uk-background-blend-saturation {
        background-blend-mode: saturation;
    }

    .uk-background-blend-color {
        background-blend-mode: color;
    }

    .uk-background-blend-luminosity {
        background-blend-mode: luminosity;
    }

    [class*='uk-align'] {
        display: block;
        margin-bottom: 20px;
    }

    * + [class*='uk-align'] {
        margin-top: 20px;
    }

    .aligncenter,
    .uk-align-center {
        margin-left: auto;
        margin-right: auto;
    }

    .alignleft,
    .uk-align-left {
        margin-top: 0;
        margin-right: 20px;
        float: left;
    }

    .alignright,
    .uk-align-right {
        margin-top: 0;
        margin-left: 20px;
        float: right;
    }

    @media (min-width: 640px) {
        .uk-align-left\@s {
            margin-top: 0;
            margin-right: 20px;
            float: left;
        }

        .uk-align-right\@s {
            margin-top: 0;
            margin-left: 20px;
            float: right;
        }
    }

    @media (min-width: 960px) {
        .uk-align-left\@m {
            margin-top: 0;
            margin-right: 20px;
            float: left;
        }

        .uk-align-right\@m {
            margin-top: 0;
            margin-left: 20px;
            float: right;
        }
    }

    @media (min-width: 1200px) {
        .uk-align-left\@l {
            margin-top: 0;
            float: left;
        }

        .uk-align-right\@l {
            margin-top: 0;
            float: right;
        }

        .alignleft,
        .uk-align-left,
        .uk-align-left\@l,
        .uk-align-left\@m,
        .uk-align-left\@s {
            margin-right: 40px;
        }

        .alignright,
        .uk-align-right,
        .uk-align-right\@l,
        .uk-align-right\@m,
        .uk-align-right\@s {
            margin-left: 40px;
        }
    }

    @media (min-width: 1600px) {
        .uk-align-left\@xl {
            margin-top: 0;
            margin-right: 40px;
            float: left;
        }

        .uk-align-right\@xl {
            margin-top: 0;
            margin-left: 40px;
            float: right;
        }
    }

    .uk-svg,
    .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
        fill: currentcolor;
    }

    .uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
        stroke: currentcolor;
    }

    .uk-svg {
        transform: translate(0, 0);
    }

    svg .uk-text-muted,
    svg.uk-text-muted {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .uk-svg-default-background {
        color: var(--shop-secondary) !important;
    }

    .uk-svg-muted-background {
        color: #2a2a2a !important;
    }

    .uk-panel {
        display: flow-root;
        position: relative;
        box-sizing: border-box;
    }

    .uk-panel > :last-child {
        margin-bottom: 0;
    }

    .uk-panel-scrollable {
        height: 170px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        resize: both;
    }

    .uk-clearfix::before {
        content: '';
        display: table-cell;
    }

    .uk-clearfix::after {
        content: '';
        display: table;
        clear: both;
    }

    .uk-float-left {
        float: left;
    }

    .uk-float-right {
        float: right;
    }

    [class*='uk-float-'] {
        max-width: 100%;
    }

    .uk-overflow-hidden {
        overflow: hidden;
    }

    .uk-overflow-auto {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .uk-overflow-auto > :last-child {
        margin-bottom: 0;
    }

    .uk-resize {
        resize: both;
    }

    .uk-resize-vertical {
        resize: vertical;
    }

    .uk-display-block {
        display: block !important;
    }

    .uk-display-inline {
        display: inline !important;
    }

    .uk-display-inline-block {
        display: inline-block !important;
    }

    [class*='uk-inline'] {
        display: inline-block;
        position: relative;
        max-width: 100%;
        vertical-align: middle;
        -webkit-backface-visibility: hidden;
    }

    .uk-inline-clip {
        overflow: hidden;
    }

    .uk-preserve-width,
    .uk-preserve-width canvas,
    .uk-preserve-width img,
    .uk-preserve-width svg,
    .uk-preserve-width video {
        max-width: none;
    }

    .uk-responsive-height,
    .uk-responsive-width {
        box-sizing: border-box;
    }

    .uk-responsive-width {
        max-width: 100% !important;
        height: auto;
    }

    .uk-responsive-height {
        max-height: 100%;
        width: auto;
        max-width: none;
    }

    .uk-border-circle {
        border-radius: 50%;
    }

    .uk-border-pill {
        border-radius: 500px;
    }

    .uk-border-rounded {
        border-radius: 5px;
    }

    .uk-inline-clip[class*='uk-border-'] {
        -webkit-transform: translateZ(0);
    }

    .uk-box-shadow-small {
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-box-shadow-medium {
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-box-shadow-large {
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    .uk-box-shadow-xlarge {
        box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    }

    [class*='uk-box-shadow-hover'] {
        transition: box-shadow 0.1s ease-in-out;
    }

    .uk-box-shadow-hover-small:hover {
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    }

    .uk-box-shadow-hover-medium:hover {
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.4);
    }

    .uk-box-shadow-hover-large:hover {
        box-shadow: 0 40px 80px -20px rgba(0, 0, 0, 0.3);
    }

    .uk-box-shadow-hover-xlarge:hover {
        box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    }

    @supports (filter: blur(0)) {
        .uk-box-shadow-bottom {
            display: inline-block;
            position: relative;
            z-index: 0;
            max-width: 100%;
            vertical-align: middle;
        }

        .uk-box-shadow-bottom::after {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            z-index: -1;
            height: 30px;
            border-radius: 100%;
            background: #444;
            filter: blur(20px);
            will-change: filter;
        }
    }

    .uk-dropcap::first-letter,
    .uk-dropcap > .tm-page-break-first-page + p::first-letter,
    .uk-dropcap > p:first-of-type::first-letter {
        display: block;
        margin-right: 10px;
        float: left;
        font-size: 4.8em;
        line-height: 1;
        color: inherit;
        margin-bottom: -2px;
    }

    @-moz-document url-prefix() {
        .uk-dropcap::first-letter,
        .uk-dropcap > .tm-page-break-first-page + p::first-letter,
        .uk-dropcap > p:first-of-type::first-letter {
            margin-top: 1.1%;
        }
    }

    @supports (-ms-ime-align: auto) {
        .uk-dropcap > .tm-page-break-first-page + p::first-letter,
        .uk-dropcap > p:first-of-type::first-letter {
            font-size: 1em;
        }
    }

    .uk-logo {
        font-size: 22px;
        font-family: 'Alegreya Sans', serif;
        color: #fff;
        text-decoration: none;
    }

    .uk-logo:focus,
    .uk-logo:hover {
        color: #cdcdcb;
        outline: 0;
        text-decoration: none;
    }

    .uk-logo-inverse {
        display: none;
    }

    .uk-disabled {
        pointer-events: none;
    }

    .uk-drag,
    .uk-drag * {
        cursor: move;
    }

    .uk-drag iframe {
        pointer-events: none;
    }

    .uk-dragover {
        box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
    }

    .uk-blend-multiply {
        mix-blend-mode: multiply;
    }

    .uk-blend-screen {
        mix-blend-mode: screen;
    }

    .uk-blend-overlay {
        mix-blend-mode: overlay;
    }

    .uk-blend-darken {
        mix-blend-mode: darken;
    }

    .uk-blend-lighten {
        mix-blend-mode: lighten;
    }

    .uk-blend-color-dodge {
        mix-blend-mode: color-dodge;
    }

    .uk-blend-color-burn {
        mix-blend-mode: color-burn;
    }

    .uk-blend-hard-light {
        mix-blend-mode: hard-light;
    }

    .uk-blend-soft-light {
        mix-blend-mode: soft-light;
    }

    .uk-blend-difference {
        mix-blend-mode: difference;
    }

    .uk-blend-exclusion {
        mix-blend-mode: exclusion;
    }

    .uk-blend-hue {
        mix-blend-mode: hue;
    }

    .uk-blend-saturation {
        mix-blend-mode: saturation;
    }

    .uk-blend-color {
        mix-blend-mode: color;
    }

    .uk-blend-luminosity {
        mix-blend-mode: luminosity;
    }

    .uk-transform-center {
        transform: translate(-50%, -50%);
    }

    .uk-transform-origin-top-left {
        transform-origin: 0 0;
    }

    .uk-transform-origin-top-center {
        transform-origin: 50% 0;
    }

    .uk-transform-origin-top-right {
        transform-origin: 100% 0;
    }

    .uk-transform-origin-center-left {
        transform-origin: 0 50%;
    }

    .uk-transform-origin-center-right {
        transform-origin: 100% 50%;
    }

    .uk-transform-origin-bottom-left {
        transform-origin: 0 100%;
    }

    .uk-transform-origin-bottom-center {
        transform-origin: 50% 100%;
    }

    .uk-transform-origin-bottom-right {
        transform-origin: 100% 100%;
    }

    .uk-flex {
        display: flex;
    }

    .uk-flex-inline {
        display: inline-flex;
    }

    .uk-flex-inline::after,
    .uk-flex-inline::before,
    .uk-flex::after,
    .uk-flex::before {
        display: none;
    }

    .uk-flex-left {
        justify-content: flex-start;
    }

    .uk-flex-center {
        justify-content: center;
    }

    .uk-flex-right {
        justify-content: flex-end;
    }

    .uk-flex-between {
        justify-content: space-between;
    }

    .uk-flex-around {
        justify-content: space-around;
    }

    @media (min-width: 640px) {
        .uk-flex-left\@s {
            justify-content: flex-start;
        }

        .uk-flex-center\@s {
            justify-content: center;
        }

        .uk-flex-right\@s {
            justify-content: flex-end;
        }

        .uk-flex-between\@s {
            justify-content: space-between;
        }

        .uk-flex-around\@s {
            justify-content: space-around;
        }
    }

    @media (min-width: 960px) {
        .uk-flex-left\@m {
            justify-content: flex-start;
        }

        .uk-flex-center\@m {
            justify-content: center;
        }

        .uk-flex-right\@m {
            justify-content: flex-end;
        }

        .uk-flex-between\@m {
            justify-content: space-between;
        }

        .uk-flex-around\@m {
            justify-content: space-around;
        }
    }

    @media (min-width: 1200px) {
        .uk-flex-left\@l {
            justify-content: flex-start;
        }

        .uk-flex-center\@l {
            justify-content: center;
        }

        .uk-flex-right\@l {
            justify-content: flex-end;
        }

        .uk-flex-between\@l {
            justify-content: space-between;
        }

        .uk-flex-around\@l {
            justify-content: space-around;
        }
    }

    @media (min-width: 1600px) {
        .uk-flex-left\@xl {
            justify-content: flex-start;
        }

        .uk-flex-center\@xl {
            justify-content: center;
        }

        .uk-flex-right\@xl {
            justify-content: flex-end;
        }

        .uk-flex-between\@xl {
            justify-content: space-between;
        }

        .uk-flex-around\@xl {
            justify-content: space-around;
        }
    }

    .uk-flex-stretch {
        align-items: stretch;
    }

    .uk-flex-top {
        align-items: flex-start;
    }

    .uk-flex-middle {
        align-items: center;
    }

    .uk-flex-bottom {
        align-items: flex-end;
    }

    .uk-flex-row {
        flex-direction: row;
    }

    .uk-flex-row-reverse {
        flex-direction: row-reverse;
    }

    .uk-flex-column {
        flex-direction: column;
    }

    .uk-flex-column-reverse {
        flex-direction: column-reverse;
    }

    .uk-flex-nowrap {
        flex-wrap: nowrap;
    }

    .uk-flex-wrap {
        flex-wrap: wrap;
    }

    .uk-flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .uk-flex-wrap-stretch {
        align-content: stretch;
    }

    .uk-flex-wrap-top {
        align-content: flex-start;
    }

    .uk-flex-wrap-middle {
        align-content: center;
    }

    .uk-flex-wrap-bottom {
        align-content: flex-end;
    }

    .uk-flex-wrap-between {
        align-content: space-between;
    }

    .uk-flex-wrap-around {
        align-content: space-around;
    }

    .uk-flex-first {
        order: -1;
    }

    .uk-flex-last {
        order: 99;
    }

    @media (min-width: 640px) {
        .uk-flex-first\@s {
            order: -1;
        }

        .uk-flex-last\@s {
            order: 99;
        }
    }

    @media (min-width: 960px) {
        .uk-flex-first\@m {
            order: -1;
        }

        .uk-flex-last\@m {
            order: 99;
        }
    }

    @media (min-width: 1200px) {
        .uk-flex-first\@l {
            order: -1;
        }

        .uk-flex-last\@l {
            order: 99;
        }
    }

    @media (min-width: 1600px) {
        .uk-flex-first\@xl {
            order: -1;
        }

        .uk-flex-last\@xl {
            order: 99;
        }
    }

    .uk-flex-none {
        flex: none;
    }

    .uk-flex-auto {
        flex: auto;
    }

    .uk-flex-1 {
        flex: 1;
    }

    .uk-margin {
        margin-bottom: 20px;
    }

    * + .uk-margin {
        margin-top: 20px !important;
    }

    .uk-margin-top {
        margin-top: 20px !important;
    }

    .uk-margin-bottom {
        margin-bottom: 20px !important;
    }

    .uk-margin-left {
        margin-left: 20px !important;
    }

    .uk-margin-right {
        margin-right: 20px !important;
    }

    .uk-margin-small {
        margin-bottom: 10px;
    }

    * + .uk-margin-small {
        margin-top: 10px !important;
    }

    .uk-margin-small-top {
        margin-top: 10px !important;
    }

    .uk-margin-small-bottom {
        margin-bottom: 10px !important;
    }

    .uk-margin-small-left {
        margin-left: 10px !important;
    }

    .uk-margin-small-right {
        margin-right: 10px !important;
    }

    .uk-margin-medium {
        margin-bottom: 40px;
    }

    * + .uk-margin-medium {
        margin-top: 40px !important;
    }

    .uk-margin-medium-top {
        margin-top: 40px !important;
    }

    .uk-margin-medium-bottom {
        margin-bottom: 40px !important;
    }

    .uk-margin-medium-left {
        margin-left: 40px !important;
    }

    .uk-margin-medium-right {
        margin-right: 40px !important;
    }

    .uk-margin-large {
        margin-bottom: 40px;
    }

    * + .uk-margin-large {
        margin-top: 40px !important;
    }

    .uk-margin-large-top {
        margin-top: 40px !important;
    }

    .uk-margin-large-bottom {
        margin-bottom: 40px !important;
    }

    .uk-margin-large-left {
        margin-left: 40px !important;
    }

    .uk-margin-large-right {
        margin-right: 40px !important;
    }

    @media (min-width: 1200px) {
        .uk-margin-large {
            margin-bottom: 70px;
        }

        * + .uk-margin-large {
            margin-top: 70px !important;
        }

        .uk-margin-large-top {
            margin-top: 70px !important;
        }

        .uk-margin-large-bottom {
            margin-bottom: 70px !important;
        }

        .uk-margin-large-left {
            margin-left: 70px !important;
        }

        .uk-margin-large-right {
            margin-right: 70px !important;
        }
    }

    .uk-margin-xlarge {
        margin-bottom: 70px;
    }

    * + .uk-margin-xlarge {
        margin-top: 70px !important;
    }

    .uk-margin-xlarge-top {
        margin-top: 70px !important;
    }

    .uk-margin-xlarge-bottom {
        margin-bottom: 70px !important;
    }

    .uk-margin-xlarge-left {
        margin-left: 70px !important;
    }

    .uk-margin-xlarge-right {
        margin-right: 70px !important;
    }

    @media (min-width: 1200px) {
        .uk-margin-xlarge {
            margin-bottom: 140px;
        }

        * + .uk-margin-xlarge {
            margin-top: 140px !important;
        }

        .uk-margin-xlarge-top {
            margin-top: 140px !important;
        }

        .uk-margin-xlarge-bottom {
            margin-bottom: 140px !important;
        }

        .uk-margin-xlarge-left {
            margin-left: 140px !important;
        }

        .uk-margin-xlarge-right {
            margin-right: 140px !important;
        }
    }

    .uk-margin-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .uk-margin-auto-top {
        margin-top: auto !important;
    }

    .uk-margin-auto-bottom {
        margin-bottom: auto !important;
    }

    .uk-margin-auto-left {
        margin-left: auto !important;
    }

    .uk-margin-auto-right {
        margin-right: auto !important;
    }

    .uk-margin-auto-vertical {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    @media (min-width: 640px) {
        .uk-margin-auto\@s {
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .uk-margin-auto-left\@s {
            margin-left: auto !important;
        }

        .uk-margin-auto-right\@s {
            margin-right: auto !important;
        }
    }

    @media (min-width: 960px) {
        .uk-margin-auto\@m {
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .uk-margin-auto-left\@m {
            margin-left: auto !important;
        }

        .uk-margin-auto-right\@m {
            margin-right: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .uk-margin-auto\@l {
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .uk-margin-auto-left\@l {
            margin-left: auto !important;
        }

        .uk-margin-auto-right\@l {
            margin-right: auto !important;
        }
    }

    @media (min-width: 1600px) {
        .uk-margin-auto\@xl {
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .uk-margin-auto-left\@xl {
            margin-left: auto !important;
        }

        .uk-margin-auto-right\@xl {
            margin-right: auto !important;
        }
    }

    .uk-margin-remove {
        margin: 0 !important;
    }

    .uk-margin-remove-top {
        margin-top: 0 !important;
    }

    .uk-margin-remove-bottom {
        margin-bottom: 0 !important;
    }

    .uk-margin-remove-left {
        margin-left: 0 !important;
    }

    .uk-margin-remove-right {
        margin-right: 0 !important;
    }

    .uk-margin-remove-vertical {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .uk-margin-remove-adjacent + *,
    .uk-margin-remove-first-child > :first-child {
        margin-top: 0 !important;
    }

    .uk-margin-remove-last-child > :last-child {
        margin-bottom: 0 !important;
    }

    @media (min-width: 640px) {
        .uk-margin-remove-left\@s {
            margin-left: 0 !important;
        }

        .uk-margin-remove-right\@s {
            margin-right: 0 !important;
        }
    }

    @media (min-width: 960px) {
        .uk-margin-remove-left\@m {
            margin-left: 0 !important;
        }

        .uk-margin-remove-right\@m {
            margin-right: 0 !important;
        }
    }

    @media (min-width: 1200px) {
        .uk-margin-remove-left\@l {
            margin-left: 0 !important;
        }

        .uk-margin-remove-right\@l {
            margin-right: 0 !important;
        }
    }

    @media (min-width: 1600px) {
        .uk-margin-remove-left\@xl {
            margin-left: 0 !important;
        }

        .uk-margin-remove-right\@xl {
            margin-right: 0 !important;
        }
    }

    .uk-padding {
        padding: 20px;
    }

    @media (min-width: 1200px) {
        .uk-padding {
            padding: 40px;
        }
    }

    .uk-padding-small {
        padding: 10px;
    }

    .uk-padding-large {
        padding: 20px;
    }

    @media (min-width: 1200px) {
        .uk-padding-large {
            padding: 70px;
        }
    }

    .uk-padding-remove {
        padding: 0 !important;
    }

    .uk-padding-remove-top {
        padding-top: 0 !important;
    }

    .uk-padding-remove-bottom {
        padding-bottom: 0 !important;
    }

    .uk-padding-remove-left {
        padding-left: 0 !important;
    }

    .uk-padding-remove-right {
        padding-right: 0 !important;
    }

    .uk-padding-remove-vertical {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .uk-padding-remove-horizontal {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    [class*='uk-position-bottom'],
    [class*='uk-position-center'],
    [class*='uk-position-left'],
    [class*='uk-position-right'],
    [class*='uk-position-top'] {
        position: absolute !important;
        max-width: 100%;
    }

    .uk-position-top {
        top: 0;
        left: 0;
        right: 0;
    }

    .uk-position-bottom {
        bottom: 0;
        left: 0;
        right: 0;
    }

    .uk-position-left {
        top: 0;
        bottom: 0;
        left: 0;
    }

    .uk-position-right {
        top: 0;
        bottom: 0;
        right: 0;
    }

    .uk-position-top-left {
        top: 0;
        left: 0;
    }

    .uk-position-top-right {
        top: 0;
        right: 0;
    }

    .uk-position-bottom-left {
        bottom: 0;
        left: 0;
    }

    .uk-position-bottom-right {
        bottom: 0;
        right: 0;
    }

    .uk-position-center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
        max-width: 100%;
        box-sizing: border-box;
    }

    [class*='uk-position-center-left'],
    [class*='uk-position-center-right'] {
        top: 50%;
        transform: translateY(-50%);
    }

    .uk-position-center-left {
        left: 0;
    }

    .uk-position-center-right {
        right: 0;
    }

    .uk-position-center-left-out {
        right: 100%;
        width: max-content;
    }

    .uk-position-center-right-out {
        left: 100%;
        width: max-content;
    }

    .uk-position-bottom-center,
    .uk-position-top-center {
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        max-width: 100%;
        box-sizing: border-box;
    }

    .uk-position-top-center {
        top: 0;
    }

    .uk-position-bottom-center {
        bottom: 0;
    }

    .uk-position-cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .uk-position-relative {
        position: relative !important;
    }

    .uk-position-absolute {
        position: absolute !important;
    }

    .uk-position-fixed {
        position: fixed !important;
    }

    .uk-position-z-index {
        z-index: 1;
    }

    .uk-position-small {
        max-width: calc(100% - (10px * 2));
        margin: 10px;
    }

    .uk-position-small.uk-position-center {
        transform: translate(-50%, -50%) translate(-10px, -10px);
    }

    .uk-position-small[class*='uk-position-center-left'],
    .uk-position-small[class*='uk-position-center-right'] {
        transform: translateY(-50%) translateY(-10px);
    }

    .uk-position-small.uk-position-bottom-center,
    .uk-position-small.uk-position-top-center {
        transform: translateX(-50%) translateX(-10px);
    }

    .uk-position-medium {
        max-width: calc(100% - (20px * 2));
        margin: 20px;
    }

    .uk-position-medium.uk-position-center {
        transform: translate(-50%, -50%) translate(-20px, -20px);
    }

    .uk-position-medium[class*='uk-position-center-left'],
    .uk-position-medium[class*='uk-position-center-right'] {
        transform: translateY(-50%) translateY(-20px);
    }

    .uk-position-medium.uk-position-bottom-center,
    .uk-position-medium.uk-position-top-center {
        transform: translateX(-50%) translateX(-20px);
    }

    .uk-position-large {
        max-width: calc(100% - (20px * 2));
        margin: 20px;
    }

    .uk-position-large.uk-position-center {
        transform: translate(-50%, -50%) translate(-20px, -20px);
    }

    .uk-position-large[class*='uk-position-center-left'],
    .uk-position-large[class*='uk-position-center-right'] {
        transform: translateY(-50%) translateY(-20px);
    }

    .uk-position-large.uk-position-bottom-center,
    .uk-position-large.uk-position-top-center {
        transform: translateX(-50%) translateX(-20px);
    }

    @media (min-width: 1200px) {
        .uk-position-large {
            max-width: calc(100% - (50px * 2));
            margin: 50px;
        }

        .uk-position-large.uk-position-center {
            transform: translate(-50%, -50%) translate(-50px, -50px);
        }

        .uk-position-large[class*='uk-position-center-left'],
        .uk-position-large[class*='uk-position-center-right'] {
            transform: translateY(-50%) translateY(-50px);
        }

        .uk-position-large.uk-position-bottom-center,
        .uk-position-large.uk-position-top-center {
            transform: translateX(-50%) translateX(-50px);
        }
    }

    .uk-transition-toggle {
        -webkit-tap-highlight-color: transparent;
    }

    .uk-transition-toggle:focus {
        outline: 0;
    }

    .uk-transition-fade,
    [class*='uk-transition-scale'],
    [class*='uk-transition-slide'] {
        transition: 0.3s ease-out;
        transition-property: opacity, transform, filter;
        opacity: 0;
    }

    .uk-transition-active.uk-active .uk-transition-fade,
    .uk-transition-toggle:focus .uk-transition-fade,
    .uk-transition-toggle:hover .uk-transition-fade {
        opacity: 1;
    }

    .uk-transition-scale-up {
        transform: scale(1, 1);
    }

    .uk-transition-scale-down {
        transform: scale(1.03, 1.03);
    }

    .uk-transition-active.uk-active .uk-transition-scale-up,
    .uk-transition-toggle:focus .uk-transition-scale-up,
    .uk-transition-toggle:hover .uk-transition-scale-up {
        opacity: 1;
        transform: scale(1.03, 1.03);
    }

    .uk-transition-active.uk-active .uk-transition-scale-down,
    .uk-transition-toggle:focus .uk-transition-scale-down,
    .uk-transition-toggle:hover .uk-transition-scale-down {
        opacity: 1;
        transform: scale(1, 1);
    }

    .uk-transition-slide-top {
        transform: translateY(-100%);
    }

    .uk-transition-slide-bottom {
        transform: translateY(100%);
    }

    .uk-transition-slide-left {
        transform: translateX(-100%);
    }

    .uk-transition-slide-right {
        transform: translateX(100%);
    }

    .uk-transition-slide-top-small {
        transform: translateY(-10px);
    }

    .uk-transition-slide-bottom-small {
        transform: translateY(10px);
    }

    .uk-transition-slide-left-small {
        transform: translateX(-10px);
    }

    .uk-transition-slide-right-small {
        transform: translateX(10px);
    }

    .uk-transition-slide-top-medium {
        transform: translateY(-50px);
    }

    .uk-transition-slide-bottom-medium {
        transform: translateY(50px);
    }

    .uk-transition-slide-left-medium {
        transform: translateX(-50px);
    }

    .uk-transition-slide-right-medium {
        transform: translateX(50px);
    }

    .uk-transition-active.uk-active [class*='uk-transition-slide'],
    .uk-transition-toggle:focus [class*='uk-transition-slide'],
    .uk-transition-toggle:hover [class*='uk-transition-slide'] {
        opacity: 1;
        transform: translate(0, 0);
    }

    .uk-transition-opaque {
        opacity: 1;
    }

    .uk-transition-slow {
        transition-duration: 0.7s;
    }

    .uk-hidden,
    [hidden] {
        display: none !important;
    }

    @media (min-width: 640px) {
        .uk-hidden\@s {
            display: none !important;
        }
    }

    @media (min-width: 960px) {
        .uk-hidden\@m {
            display: none !important;
        }
    }

    @media (min-width: 1200px) {
        .uk-hidden\@l {
            display: none !important;
        }
    }

    @media (min-width: 1600px) {
        .uk-hidden\@xl {
            display: none !important;
        }
    }

    @media (max-width: 639px) {
        .uk-visible\@s {
            display: none !important;
        }
    }

    @media (max-width: 959px) {
        .uk-visible\@m {
            display: none !important;
        }
    }

    @media (max-width: 1199px) {
        .uk-visible\@l {
            display: none !important;
        }
    }

    @media (max-width: 1599px) {
        .uk-visible\@xl {
            display: none !important;
        }
    }

    .uk-invisible {
        visibility: hidden !important;
    }

    .uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
        position: absolute !important;
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
    }

    .uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
        opacity: 0 !important;
    }

    .uk-visible-toggle {
        -webkit-tap-highlight-color: transparent;
    }

    .uk-visible-toggle:focus {
        outline: 0;
    }

    @media (pointer: coarse) {
        .uk-hidden-touch {
            display: none !important;
        }
    }

    .uk-hidden-notouch {
        display: none !important;
    }

    @media (pointer: coarse) {
        .uk-hidden-notouch {
            display: block !important;
        }
    }

    .uk-dark {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-link,
    .uk-dark a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-link-toggle:focus .uk-link,
    .uk-dark .uk-link-toggle:hover .uk-link,
    .uk-dark .uk-link:hover,
    .uk-dark a:hover {
        color: var(--shop-secondary);
    }

    .uk-dark :not(pre) > code,
    .uk-dark :not(pre) > kbd,
    .uk-dark :not(pre) > samp {
        color: rgba(14, 14, 14, 0.7);
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark em {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-h1,
    .uk-dark .uk-h2,
    .uk-dark .uk-h3,
    .uk-dark .uk-h4,
    .uk-dark .uk-h5,
    .uk-dark .uk-h6,
    .uk-dark .uk-heading-2xlarge,
    .uk-dark .uk-heading-large,
    .uk-dark .uk-heading-medium,
    .uk-dark .uk-heading-small,
    .uk-dark .uk-heading-xlarge,
    .uk-dark h1,
    .uk-dark h2,
    .uk-dark h3,
    .uk-dark h4,
    .uk-dark h5,
    .uk-dark h6 {
        color: var(--shop-secondary);
    }

    .uk-dark blockquote {
        color: var(--shop-secondary);
        border-left-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark blockquote footer {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-hr,
    .uk-dark hr {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-link-muted a,
    .uk-dark a.uk-link-muted {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-link-muted a:hover,
    .uk-dark .uk-link-toggle:focus .uk-link-muted,
    .uk-dark .uk-link-toggle:hover .uk-link-muted,
    .uk-dark a.uk-link-muted:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-link-text a:hover,
    .uk-dark .uk-link-toggle:focus .uk-link-text,
    .uk-dark .uk-link-toggle:hover .uk-link-text,
    .uk-dark a.uk-link-text:hover {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-link-heading a:hover,
    .uk-dark .uk-link-toggle:focus .uk-link-heading,
    .uk-dark .uk-link-toggle:hover .uk-link-heading,
    .uk-dark a.uk-link-heading:hover {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-small {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-medium {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-large {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-xlarge {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-2xlarge {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-heading-divider {
        border-bottom-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-heading-bullet::before {
        border-left-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-heading-line > ::after,
    .uk-dark .uk-heading-line > ::before {
        border-bottom-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-divider-icon {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Ccircle%20fill%3D%22rgba%2814,%2014,%2014,%200.08%29%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-dark .uk-divider-icon::after,
    .uk-dark .uk-divider-icon::before {
        border-bottom-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-divider-small::after {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-divider-vertical {
        border-left-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-list-muted > ::before {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-list-emphasis > ::before {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-list-primary > ::before {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-list-secondary > ::before {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-list-bullet > ::before {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%204%204%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Ccircle%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20cx%3D%222%22%20cy%3D%222%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-dark .tm-child-list-divider > ul > :nth-child(n + 2),
    .uk-dark .uk-list-divider > :nth-child(n + 2) {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-list-striped > :nth-of-type(odd) {
        background-color: rgba(14, 14, 14, 0.1);
    }

    .uk-dark .uk-table th {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-table caption {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-table tbody tr.uk-active,
    .uk-dark .uk-table > tr.uk-active {
        background: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-table-divider > :first-child > tr:not(:first-child),
    .uk-dark .uk-table-divider > :not(:first-child) > tr,
    .uk-dark .uk-table-divider > tr:not(:first-child) {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-table-striped tbody tr:nth-of-type(odd),
    .uk-dark .uk-table-striped > tr:nth-of-type(odd) {
        background: rgba(14, 14, 14, 0.1);
    }

    .uk-dark .uk-table-hover tbody tr:hover,
    .uk-dark .uk-table-hover > tr:hover {
        background: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-icon-link {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-icon-link:focus,
    .uk-dark .uk-icon-link:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-active > .uk-icon-link,
    .uk-dark .uk-icon-link:active {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-icon-button {
        background-color: rgba(14, 14, 14, 0.1);
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-icon-button:focus,
    .uk-dark .uk-icon-button:hover {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.7);
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
    }

    .uk-dark .uk-icon-button:active {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-input,
    .uk-dark .uk-select,
    .uk-dark .uk-textarea {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.7);
        background-clip: padding-box;
        border-color: rgba(14, 14, 14, 0.08) !important;
    }

    .uk-dark .uk-input:focus,
    .uk-dark .uk-select:focus,
    .uk-dark .uk-textarea:focus {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.7);
        border-color: transparent !important;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
    }

    .uk-dark .uk-input::-ms-input-placeholder {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-input::placeholder {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-textarea::-ms-input-placeholder {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-textarea::placeholder {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-select:not([multiple]):not([size]) {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-dark .uk-input[list]:focus,
    .uk-dark .uk-input[list]:hover {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-dark .uk-checkbox,
    .uk-dark .uk-radio {
        background-color: transparent;
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-checkbox:focus,
    .uk-dark .uk-radio:focus {
        background-color: transparent;
        border-color: var(--shop-secondary);
    }

    .uk-dark .uk-checkbox:checked,
    .uk-dark .uk-checkbox:indeterminate,
    .uk-dark .uk-radio:checked {
        background-color: var(--shop-secondary);
        border-color: transparent;
    }

    .uk-dark .uk-checkbox:checked:focus,
    .uk-dark .uk-checkbox:indeterminate:focus,
    .uk-dark .uk-radio:checked:focus {
        background-color: var(--shop-secondary);
    }

    .uk-dark .uk-radio:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark .uk-checkbox:checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23FFFFFF%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
    }

    .uk-dark .uk-checkbox:indeterminate {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23FFFFFF%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark .uk-form-label {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-form-icon {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-form-icon:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-button-default {
        background-color: rgba(14, 14, 14, 0.12);
        color: rgba(14, 14, 14, 0.7);
        border-color: transparent;
        box-shadow: none;
    }

    .uk-dark .uk-button-default:focus,
    .uk-dark .uk-button-default:hover {
        background-color: rgba(14, 14, 14, 0.24);
        color: var(--shop-secondary);
        border-color: transparent;
        box-shadow: none;
    }

    .uk-dark .uk-button-default.uk-active,
    .uk-dark .uk-button-default:active {
        background-color: rgba(14, 14, 14, 0.12);
        color: #fff;
        border-color: transparent;
    }

    .uk-dark .uk-button-primary {
        background-color: var(--shop-main);
        color: var(--shop-secondary);
        border-color: transparent;
    }

    .uk-dark .uk-button-primary:focus,
    .uk-dark .uk-button-primary:hover {
        background-color: #4cb3e4;
        color: var(--shop-secondary);
        border-color: transparent;
    }

    .uk-dark .uk-button-primary.uk-active,
    .uk-dark .uk-button-primary:active {
        background-color: #1d89bc;
        color: rgba(14, 14, 14, 0.8);
        border-color: transparent;
    }

    .uk-dark .uk-button-secondary {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.7);
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-button-secondary:focus,
    .uk-dark .uk-button-secondary:hover {
        background-color: transparent;
        color: var(--shop-secondary);
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-button-secondary.uk-active,
    .uk-dark .uk-button-secondary:active {
        background-color: var(--shop-secondary);
        color: #fff;
        border-color: var(--shop-secondary);
    }

    .uk-dark .uk-button-text {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-button-text:focus,
    .uk-dark .uk-button-text:hover {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-button-text:disabled {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-button-link {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-button-link:focus,
    .uk-dark .uk-button-link:hover {
        color: var(--shop-secondary);
    }

    .uk-dark.uk-card-badge {
        background-color: var(--shop-secondary);
        color: #fff;
    }

    .uk-dark .uk-close {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-close:focus,
    .uk-dark .uk-close:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-totop {
        color: rgba(14, 14, 14, 0.5);
        background-color: transparent;
    }

    .uk-dark .uk-totop:focus,
    .uk-dark .uk-totop:hover {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
    }

    .uk-dark .uk-totop:active {
        color: var(--shop-secondary);
        background-color: transparent;
    }

    .uk-dark .uk-marker {
        background: #2f2f2f;
        color: #cdcdcb;
    }

    .uk-dark .uk-marker:focus,
    .uk-dark .uk-marker:hover {
        color: #cdcdcb;
    }

    .uk-dark .uk-badge {
        background-color: var(--shop-secondary);
        color: #fff !important;
    }

    .uk-dark .uk-label {
        background-color: var(--shop-secondary);
        color: #fff;
    }

    .uk-dark .uk-article-title {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-article-meta {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-search-input {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-search-input:-ms-input-placeholder {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-search-input::placeholder {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-search .uk-search-icon {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-search .uk-search-icon:hover {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-search-default .uk-search-input {
        background-color: transparent;
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-search-default .uk-search-input:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
    }

    .uk-dark .uk-search-navbar .uk-search-input {
        background-color: transparent;
    }

    .uk-dark .uk-search-large .uk-search-input {
        background-color: transparent;
    }

    .uk-dark .uk-search-toggle {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-search-toggle:focus,
    .uk-dark .uk-search-toggle:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-accordion-title {
        color: var(--shop-secondary);
        background-color: transparent;
    }

    .uk-dark .uk-accordion-title:focus,
    .uk-dark .uk-accordion-title:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-leader-fill::after {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-countdown-number,
    .uk-dark .uk-countdown-separator {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-countdown-label {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-grid-divider > :not(.uk-first-column)::before {
        border-left-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-nav-parent-icon > .uk-parent > a::after {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark .uk-nav-default > li > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-nav-default > li > a:focus,
    .uk-dark .uk-nav-default > li > a:hover {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
    }

    .uk-dark .uk-nav-default > li.uk-active > a {
        color: var(--shop-secondary);
        background-color: transparent;
    }

    .uk-dark .uk-nav-default .uk-nav-header {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-nav-default .uk-nav-divider {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-nav-default .uk-nav-sub a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-nav-default .uk-nav-sub a:focus,
    .uk-dark .uk-nav-default .uk-nav-sub a:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-nav-default .uk-nav-sub li.uk-active > a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-nav-primary > li > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-nav-primary > li > a:focus,
    .uk-dark .uk-nav-primary > li > a:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-nav-primary > li.uk-active > a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-nav-primary .uk-nav-header {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-nav-primary .uk-nav-divider {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-nav-primary .uk-nav-sub a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-nav-primary .uk-nav-sub a:focus,
    .uk-dark .uk-nav-primary .uk-nav-sub a:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-nav-primary .uk-nav-sub li.uk-active > a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
        border-top-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-navbar-nav > li > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-navbar-nav > li > a::before {
        background-color: transparent;
    }

    .uk-dark .uk-navbar-nav > li:hover > a,
    .uk-dark .uk-navbar-nav > li > a.uk-open,
    .uk-dark .uk-navbar-nav > li > a:focus {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-navbar-nav > li > a:active {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-navbar-nav > li > a:active::before {
        background-color: var(--shop-secondary);
    }

    .uk-dark .uk-navbar-nav > li.uk-active > a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-navbar-nav > li.uk-active > a::before {
        background-color: var(--shop-secondary);
    }

    .uk-dark .uk-navbar-item {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-navbar-toggle {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-navbar-toggle.uk-open,
    .uk-dark .uk-navbar-toggle:focus,
    .uk-dark .uk-navbar-toggle:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-subnav > * > :first-child {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-subnav > * > a:focus,
    .uk-dark .uk-subnav > * > a:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-subnav > .uk-active > a {
        color: var(--shop-secondary);
    }

    .uk-dark .uk-subnav-divider > :nth-child(n + 2):not(.uk-first-column)::before {
        border-left-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-subnav-pill > * > :first-child {
        background-color: transparent;
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-subnav-pill > * > a:focus,
    .uk-dark .uk-subnav-pill > * > a:hover {
        background-color: rgba(14, 14, 14, 0.1);
        color: rgba(14, 14, 14, 0.7);
        box-shadow: none;
    }

    .uk-dark .uk-subnav-pill > * > a:active {
        background-color: rgba(14, 14, 14, 0.1);
        color: rgba(14, 14, 14, 0.7);
        box-shadow: none;
    }

    .uk-dark .uk-subnav-pill > .uk-active > a {
        background-color: var(--shop-secondary);
        color: #fff;
    }

    .uk-dark .uk-subnav > .uk-disabled > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-breadcrumb > * > * {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-breadcrumb > * > :focus,
    .uk-dark .uk-breadcrumb > * > :hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-breadcrumb > .uk-disabled > * {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-breadcrumb > :last-child > * {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-breadcrumb > :nth-child(n + 2):not(.uk-first-column)::before {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-pagination > * > * {
        color: rgba(14, 14, 14, 0.5);
        background-color: transparent;
    }

    .uk-dark .uk-pagination > * > :focus,
    .uk-dark .uk-pagination > * > :hover {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
        box-shadow: none;
    }

    .uk-dark .uk-pagination > .uk-active > * {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
    }

    .uk-dark .uk-pagination > .uk-disabled > * {
        color: rgba(14, 14, 14, 0.5);
        background-color: transparent;
    }

    .uk-dark .uk-tab::before {
        border-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-tab > * > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-tab > * > a:focus,
    .uk-dark .uk-tab > * > a:hover {
        color: rgba(14, 14, 14, 0.7);
        border-color: transparent;
    }

    .uk-dark .uk-tab > .uk-active > a {
        color: var(--shop-secondary);
        border-color: var(--shop-secondary);
    }

    .uk-dark .uk-tab > .uk-disabled > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-slidenav {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
    }

    .uk-dark .uk-slidenav:focus,
    .uk-dark .uk-slidenav:hover {
        color: rgba(14, 14, 14, 0.95);
        background-color: transparent;
    }

    .uk-dark .uk-slidenav:active {
        color: rgba(14, 14, 14, 0.7);
        background-color: transparent;
    }

    .uk-dark .uk-dotnav > * > * {
        background-color: rgba(14, 14, 14, 0.2);
    }

    .uk-dark .uk-dotnav > * > :focus,
    .uk-dark .uk-dotnav > * > :hover {
        background-color: var(--shop-secondary);
    }

    .uk-dark .uk-dotnav > * > :active {
        background-color: rgba(14, 14, 14, 0.2);
    }

    .uk-dark .uk-dotnav > .uk-active > * {
        background-color: var(--shop-secondary);
    }

    .uk-dark .uk-thumbnav > * > * {
        background-color: rgba(14, 14, 14, 0.4);
    }

    .uk-dark .uk-thumbnav > * > :focus,
    .uk-dark .uk-thumbnav > * > :hover {
        background-color: transparent;
    }

    .uk-dark .uk-thumbnav > .uk-active > * {
        background-color: transparent;
    }

    .uk-dark .uk-iconnav > * > a {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-iconnav > * > a:focus,
    .uk-dark .uk-iconnav > * > a:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-iconnav > .uk-active > a {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-text-lead {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-text-meta {
        color: rgba(14, 14, 14, 0.5);
    }

    .uk-dark .uk-text-muted {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-text-emphasis {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-text-primary {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-text-secondary {
        color: var(--shop-secondary) !important;
    }

    .uk-dark .uk-column-divider {
        column-rule-color: rgba(14, 14, 14, 0.08);
    }

    .uk-dark .uk-dropcap p:first-of-type::first-letter,
    .uk-dark .uk-dropcap::first-letter {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-logo {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-logo:focus,
    .uk-dark .uk-logo:hover {
        color: rgba(14, 14, 14, 0.7);
    }

    .uk-dark .uk-logo > :not(.uk-logo-inverse):not(:only-of-type) {
        display: none;
    }

    .uk-dark .uk-logo-inverse {
        display: inline;
    }

    .uk-dark .uk-accordion-title::before {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark .uk-open > .uk-accordion-title::before {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%2814,%2014,%2014,%200.7%29%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E');
    }

    .uk-dark svg .uk-text-muted,
    .uk-dark svg.uk-text-muted {
        color: rgba(14, 14, 14, 0.5) !important;
    }

    .uk-dark .uk-svg-default-background {
        color: transparent !important;
    }

    .uk-dark .uk-svg-muted-background {
        color: rgba(14, 14, 14, 0.1) !important;
    }

    .uk-dark .tm-section-title {
        color: rgba(14, 14, 14, 0.7);
    }

    @media print {
        *,
        ::after,
        ::before {
            background: 0 0 !important;
            color: #000 !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        blockquote,
        pre {
            border: 1px solid #999;
            page-break-inside: avoid;
        }

        thead {
            display: table-header-group;
        }

        img,
        tr {
            page-break-inside: avoid;
        }

        img {
            max-width: 100% !important;
        }

        @page {
            margin: 0.5cm;
        }

        h2,
        h3,
        p {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
    }

    html {
        overflow-y: scroll;
    }

    body {
        overflow: hidden;
    }

    .tm-page {
        position: relative;
    }

    @media (min-width: 1500px) {
        .tm-page-container {
            background-color: #272727;
        }

        .tm-page-container .tm-page {
            max-width: 1500px;
            background: var(--shop-secondary);
        }

        .tm-page-width {
            max-width: 1500px;
        }

        .tm-page-margin-top {
            margin-top: 70px;
        }

        .tm-page-margin-bottom {
            margin-bottom: 70px;
        }
    }

    .tm-cookie-banner {
        z-index: 1040;
    }

    .tm-toolbar {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px;
        position: relative;
    }

    .tm-toolbar .uk-subnav > * > :first-child {
        font-size: 12px;
    }

    .tm-toolbar-default {
        background: rgba(0, 0, 0, 0.74);
    }

    .tm-header {
        position: relative;
    }
    .tm-header-mobile {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 980;
    }

    .tm-headerbar-top {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .tm-headerbar-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .tm-headerbar-stacked {
        margin-top: 20px;
    }

    .tm-headerbar-top.tm-headerbar-default {
        background: var(--shop-secondary);
    }

    .tm-headerbar-bottom.tm-headerbar-default {
        background: var(--shop-secondary);
    }

    .tm-header-overlay {
        position: absolute;
        z-index: 980;
        left: 0;
        right: 0;
    }

    .tm-header-mobile-slide {
        z-index: 1020;
    }

    .uk-navbar-item .uk-subnav > * > :first-child {
        font-size: 16px;
    }

    @media (min-width: 640px) {
        .tm-sidebar {
            min-width: 200px;
        }
    }

    .uk-dropdown-navbar.uk-dropdown-stack {
        min-width: 0 !important;
        max-width: none !important;
    }

    .tm-error-icon {
        font-size: 250px;
    }

    .tm-error-headline {
        font-size: 100px;
    }

    .tm-offline {
        width: 300px;
    }

    .hljs {
        padding: 0;
        background: 0 0;
        overflow: visible;
    }

    .tm-section-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 11px;
        line-height: 1.6;
        -webkit-writing-mode: vertical-lr;
        writing-mode: vertical-lr;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .tm-section-title > ::before {
        content: '';
        position: absolute;
        top: -30px;
        left: calc(50% - (1px / 2));
        height: 15px;
        width: 1px;
    }

    .tm-section-title > ::before {
        background-color: currentColor;
    }

    .tm-section-title > * {
        -ms-writing-mode: tb-lr;
        display: inline-block;
    }

    @supports (-ms-ime-align: auto) {
        .tm-section-title {
            writing-mode: inherit;
        }

        .tm-section-title > * {
            writing-mode: vertical-lr;
        }
    }

    .tm-rotate-180 {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .tm-grid-expand > * {
        flex-grow: 1;
    }

    .tm-grid-expand > .uk-width-expand {
        flex-grow: 999;
    }

    @media (min-width: 640px) {
        .tm-grid-expand > .uk-width-expand\@s {
            flex-grow: 999;
        }
    }

    @media (min-width: 960px) {
        .tm-grid-expand > .uk-width-expand\@m {
            flex-grow: 999;
        }
    }

    @media (min-width: 1200px) {
        .tm-grid-expand > .uk-width-expand\@l {
            flex-grow: 999;
        }
    }

    @media (min-width: 1600px) {
        .tm-grid-expand > .uk-width-expand\@xl {
            flex-grow: 999;
        }
    }

    .tm-box-decoration-default,
    .tm-box-decoration-primary,
    .tm-box-decoration-secondary {
        position: relative;
        z-index: 0;
    }

    .tm-box-decoration-default::before,
    .tm-box-decoration-primary::before,
    .tm-box-decoration-secondary::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;
    }

    .tm-box-decoration-default::before {
        transform: translate(-20px, 20px);
        background-color: #373737;
    }

    .tm-box-decoration-primary::before {
        transform: translate(20px, 20px);
        background-color: #373737;
    }

    .tm-box-decoration-secondary::before {
        transform: translate(20px, -20px);
        background-color: #373737;
    }

    .tm-box-decoration-inverse.tm-box-decoration-default::before {
        background-color: rgba(14, 14, 14, 0.1);
    }

    .tm-box-decoration-inverse.tm-box-decoration-primary::before {
        background-color: rgba(14, 14, 14, 0.1);
    }

    .tm-box-decoration-inverse.tm-box-decoration-secondary::before {
        background-color: rgba(14, 14, 14, 0.1);
    }

    .uk-font-default {
        font-family: 'Alegreya Sans', serif;
        font-weight: inherit;
    }

    .uk-font-primary {
        font-family: inherit;
        font-weight: 300;
    }

    .uk-font-secondary {
        font-family: inherit;
        font-weight: 500;
    }

    .uk-font-tertiary {
        font-family: inherit;
        font-weight: inherit;
    }

    .tm-position-z-index-high {
        z-index: 990;
    }

    .tm-page-break::first-letter {
        display: inline !important;
        margin: 0 !important;
        float: none !important;
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
    }
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas {
    right: 0;
    left: auto;
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas-bar {
    left: auto;
    right: -270px;
}

@media (min-width: 960px) {
    .uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas-bar {
        right: -350px;
    }
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-open > .uk-offcanvas-bar {
    left: auto;
    right: 0;
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas-bar-animation {
    transition-property: right;
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas-reveal .uk-offcanvas-bar {
    left: auto;
    right: 0;
}

.uk-offcanvas-flip #custom-theme-noa--header .uk-offcanvas-reveal {
    right: 0;
    left: auto;
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation #custom-theme-noa--header {
    left: 270px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation #custom-theme-noa--header {
    left: -270px;
}

@media (min-width: 960px) {
    :not(.uk-offcanvas-flip).uk-offcanvas-container-animation #custom-theme-noa--header {
        left: 350px;
    }

    .uk-offcanvas-flip.uk-offcanvas-container-animation #custom-theme-noa--header {
        left: -350px;
    }
}